<template>
    <v-container  class="fill-height fill-width" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4" lg="4">
                <v-card class="elevation-12 vcardbg" outlined>
                    <v-img
                    src="/SSCR.png"
                    alt="San Sebastian College-Recoletos"
                    contain
                    height="150"
                    class="mt-5 mb-2"
                    ></v-img>
                    <v-card-title>Forgot Password</v-card-title>
                    <v-card-text>
                        <v-divider class="linedivider my-5"></v-divider>
                        <div v-if="alert">
                            <v-alert :type="alerttype">
                            {{  message }}
                            </v-alert>
                        </div>
                        <div v-else>
                            <v-form lazy-validation v-model="valid" ref="RefForgotPassword">
                            <div v-if="error">
                                <v-alert type="error">
                                {{ message }}
                                </v-alert>
                            </div>
                            <v-text-field type="password" label="Enter New Password" prepend-inner-icon="mdi-lock" v-model="password" class="font-weight-bold" :rules="[rules.required]" outlined></v-text-field>
                            <v-text-field type="password" label="Confirm Password" prepend-inner-icon="mdi-lock" v-model="confirm_password" class="font-weight-bold" :rules="[rules.required, passwordMatch]" outlined></v-text-field>
                            </v-form>
                        </div>  
                    </v-card-text>
                    <v-divider class="linedivider"></v-divider>
                    <v-card-actions>
                        <v-btn
                            class="ma-2"
                            color="orange darken-2"
                            dark
                            elevation="4"
                            x-large
                            @click="Login"
                        >
                            <v-icon left>
                                mdi-arrow-left
                            </v-icon>
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!alert"
                            class="ma-2 px-11"
                            :color="valid ? 'orange darken-2' : 'blue-grey lighten-2'"
                            dark
                            elevation="4"
                            x-large
                            @click="ChangePassword"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import studentService from '@/services/student'
export default {
    name: 'ResetPassword',
     data: () => ({
         valid: true,
         alert: false,
         error: false,
         message: '',
         password: '',
         confirm_password: '',
         alerttype: '',
         rules: {
            required: value => !!value || 'Required.'
         }

     }),
     mounted () {
         this.checkToken()
     },
     computed: {
        passwordMatch () {
            if (this.password === this.confirm_password) {
                return true
            } else {
                return 'Password did not match'
            }
        }
     },
     methods: {
         async checkToken() {
             try {
                const result = await studentService.CheckPasswordResetToken({
                    token: this.$route.params.token
                })
             } catch (error) {
                 this.alert = true
                 this.alerttype = 'warning'
                 this.message = error.response.data.error
             }
         },
         async ChangePassword(){
            try {
                if (this.$refs.RefForgotPassword.validate()) {  
                    const result = await studentService.ChangePassword({
                        token: this.$route.params.token,
                        password: this.password
                    })
                    if (result.status === 200) {
                        this.alert = true
                        this.alerttype = 'success'
                        this.message = 'Your password is successfully updated!'
                        setTimeout(() => {
                            this.$router.push({name: 'Login'})
                        }, 2500);
                    }
                }
            } catch (error) {
                console.log(error)
                this.error = true
                this.message = error.response.data.error
            }
         }, 
         Login() {
             this.$router.push({name: 'Login'})
         }
     }
    
}
</script>