<template>
    <div>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                    <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="200" class="mt-5 mb-2">
                    </v-img>
                    <v-divider class="linedivider my-10"></v-divider>
                    <v-card-text class="text-center">
                        <h2 class="my-2 font-weight-black dark">{{ message }}</h2>
                    </v-card-text>
                    <v-divider class="linedivider my-5 text-center"></v-divider>
                    <v-card-actions class="mb-3 mr-3">
                        <v-spacer></v-spacer>
                        <v-btn class="font-weight-bold" color="orange darken-2" dark large @click="Home">
                            <v-icon small class="mr-2">
                                mdi-home
                            </v-icon>
                            Home
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import studentService from '@/services/student'

export default {
    name: 'DragonPayInvalid',
    data: () => ({
        message: '',
    }),
    mounted() {
      this.message = 'Invalid Transaction please try again. '
        this.GoBackToHome()
    },
    methods: {
        GoBackToHome() {
            // setTimeout(() => {
            //     //localStorage.setItem('token',response.data.token)
            //     //VueCookies.set('Token', response.data.token, '2h')
            //     this.$router.push({ name: 'Home' })
            // }, 10000)
        },
        Home() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>