<template>
  <div>
    <v-container class="mt-4 pa-5 mb-4">
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" lg="3" md="4" xl="3">
                <v-text-field v-model="termDesc" outlined dense class="font-weight-bold"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" lg="2" md="2" xl="2">
               
                <v-btn color="orange darken-2" class="font-weight-bold ma-1" dark small @click="termDialog=true">
                    Change Term </v-btn>
            </v-col>
        </v-row>
        <v-row dense v-if="isCollege">
            <v-col cols="12" xs="12" sm="12" lg="12" xl="12">
                 <v-data-table :headers="college" :items="student_grades" class="elevation-1" hide-default-footer disable-pagination>
                        <template v-for="header in college.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )" v-slot:[`item.${header.value}`]="{ header, value }">
                            {{ header.formatter(value) }}
                        </template>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>STUDENT GRADES</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                    </v-data-table>
            </v-col>
        </v-row>
        <v-row dense v-else>
            <v-col cols="12" xs="12" sm="12" lg="12" xl="12">
                <v-data-table :headers="basicEd" :items="student_grades" class="elevation-1" hide-default-footer disable-pagination>
                    <template v-for="header in basicEd.filter((header) =>
                        header.hasOwnProperty('formatter')
                    )" v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>STUDENT GRADES</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
    <v-dialog v-model="termDialog">
        <v-card class="mx-auto my-12 vcardbg" max-width="374">
            <v-card-title>Change Term</v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field class="font-weight-bold" dense outlined label="Year" v-model="termYear"></v-text-field>
                        <v-col cols="12">
                            <v-select
                                class="font-weight-bol"
                                outlined
                                dense
                                label="Please Select Term"
                                v-model="termCode"
                                item-text="text"
                                item-value="value"
                                :items="termCodes"
                            ></v-select>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange darken-2" class="font-weight-bold ma-1" dark small @click="ChangeTerm">
                    Change Term
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import studentService from '@/services/student'
import numeral from 'numeral'
import _ from 'lodash'

export default {
    name: 'Grades',
    props: ['student'],
    data: () => ({
        headers: '',
        student_grades: [],
        isCollege: true,
        termDialog: false,
        termDesc: '',
        termCode: '',
        termYear: '',
        term:'',
        termCodes: [
            { value: '00', text: 'School Year' },
            { value: '21', text: '1st Semester' },
            { value: '22', text: '2nd Semester' },
            { value: '23', text: 'Summer' },
            { value: '31', text: '1st Trimester' },
            { value: '32', text: '2nd Trimester' },
            { value: '33', text: '3rd Trimester' },
            { value: '41', text: '1st Quarter' },
            { value: '42', text: '2nd Quarter' },
            { value: '43', text: '3rd Quarter' },
            { value: '44', text: '4th Quarter' },
        ],

    }),
    computed: {
        college() {
            return [
                { text: 'ROW', align: 'start', 'sortable': false, value: 'row' },
                { text: 'Faculty Name', value: 'faculty_name', align: 'left'},
                { text: 'Subject No', value: 'subj_no', align: 'left' },
                { text: 'Subject', value: 'subject', align: 'left' },
                { text: 'Prelim', value: 'prelim', align: 'center', class: 'mr-1'},
                { text: 'Mid-Term', value: 'midterm', align: 'center', class: 'mr-1' },
                { text: 'Pre-Final', value: 'preFinal', align: 'center', class: 'mr-1' },
                { text: 'Final', value: 'final', align: 'center', class: 'mr-1' },
            ]
        },
        basicEd() {
            return [
                { text: 'ROW', align: 'start', 'sortable': false, value: 'row' },
                { text: 'Faculty Name', value: 'faculty_name', align: 'left' },
                { text: 'Subject No', value: 'subj_no', align: 'left' },
                { text: 'Subject', value: 'subject', align: 'left' },
                { text: '1st Quarter', value: 'firstQ',  align: 'center', class: 'mr-1' },
                { text: '2nd Quarter', value: 'secondQ', align: 'center', class: 'mr-1' },
                { text: '3rd Quarter', value: 'thirdQ', align: 'center', class: 'mr-1' },
                { text: '4th Quarter', value: 'fourthQ', align: 'center', class: 'mr-1' },
            ]
        }
    },
    mounted() {
        this.SetTerm()
        this.GetStudentGrades()
    },
    methods: {
        async ChangeTerm() {
           try {
	            const result = await studentService.GetSelectedTerm({termYear: this.termYear, termCode: this.termCode})
	            if (result.status === 200) {
	                this.term = result.data.result.term
	                this.termDesc = result.data.result.term_desc,
	                this.termDialog = false
                    this.GetStudentGrades()
	            } else {
	                console.log(result.data.error)
	            }
            } catch (error) {
                console.log(error)
            }
        },
        async SetTerm() {

            const result = await studentService.GetCurrentTerm()
            if (result.status === 200) {
                this.term = result.data.result.term
                this.termDesc = result.data.result.term_desc
            } else {
                console.log(result)
            }
        },  
        async GetStudentGrades() {
            this.headers = await this.getHeaderType()
            console.log(this.headers)
            let data = []
            const result = await studentService.GetStudentGrades({student_no: this.student.stud_no, term: this.term})
            if (result.status === 200) {
                if (this.headers === 'college') {
                    this.isCollege = true
                    
                    this.student_grades = []
                    result.data.result.forEach((value, index) => {
                        data = []
                        data = {
                            row: index + 1,
                            faculty_name: value.faculty_name,
                            subj_no: value.subj_no,
                            subject: value.subj_title,
                            prelim: this.setGrader(value.prelim_grd),
                            midterm: this.setGrader(value.mid_term_grd),
                            preFinal: this.setGrader(value.semi_final_grd),
                            final: this.setGrader(value.final_grd),
                        }
                        this.student_grades.push(data)
                    })
                } else {
                    this.isCollege = false
                    this.student_grades = []
                    result.data.result.forEach((value, index) => {
                        data = []
                        data = {
                            row: index + 1,
                            faculty_name: value.faculty_name,
                            subj_no: value.subj_no,
                            subject: value.subj_title,
                            firstQ: this.setGrader(value.prelim_grd),
                            secondQ: this.setGrader(value.mid_term_grd),
                            thirdQ: this.setGrader(value.semi_final_grd),
                            fourthQ: this.setGrader(value.final_grd),
                            
                        }
                        this.student_grades.push(data)
                    })
                }
               
            }            
        },
        async getHeaderType() {
            const heading = await studentService.getHeaderType(this.student.stud_no)
            return heading.data.result
        },
        setGrader(grader) {
            switch (grader) {
                case 9:
                    return 'DO-(Dropped official)'
                    break;
                case 8:
                    return 'DA-(Dropped due to Absences)'
                    break;
                case 7:
                    return 'NA-(No Attendance)'
                    break;
                case 6: 
                    return 'INC-(Incomplete)'
                    break;
                case 5:
                    return 'InPs-(In Progress)'
                    break;
                case null:
                    return 0
                    break;    
                default:
                    return grader ?? 0
                    break;
            }
        },
        checkFinalizeGrade(finalized_date,grade){
            const grdStatus = [9,8,7,6,5]
           if (_.isNull(finalized_date)) {
                // if (grdStatus.includes(grade)){
                //     return this.setGrader(grade)
                // }
           } else {
                return this.setGrader(grade)
           }
        },
        formatExample(value) {
            if (value >= 0) {
                return numeral(value).format('0,0.00')
            } else {
                if (typeof value !== 'string') {
                    value = value * -1

                    return '(' + numeral(value).format('0,0.00') + ')'
                } else {
                    return numeral(value).format('0,0.00')
                }
            }
        },
    }

}
</script>

<style>

</style>