import Vue from 'vue'
import Vuelidate from 'vuelidate'
import vueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import AsyncComputed from 'vue-async-computed'
import VerifyEmail from '../views/Verifyemail.vue'
import VerifyAccount from  '../views/VerifyAccount.vue'
import ResetPassword from '../views/ResetPassword.vue'
import DragonPayResponse from '../views/DragonPayResponse.vue'
import DragonPayInvalid from '../views/DragonPayResponse/DragonPayInvalid.vue'
import DragonPaySuccess from '../views/DragonPayResponse/DragonPaySuccess.vue'
import DragonPayFailed from '../views/DragonPayResponse/DragonPayFailed.vue'
import DragonPayPending from '../views/DragonPayResponse/DragonPayPending.vue'

Vue.use(AsyncComputed)
Vue.use(Vuelidate)
Vue.use(vueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/verifyemail/:token',
    name: 'VerifyEmail',
    component: VerifyEmail
  },
  {
    path: '/confirmacount/:token',
    name: 'VerifyAccount',
    component: VerifyAccount
  },
  {
    path: '/password/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/dragonpay',
    name: 'DragonPayResponse',
    component: DragonPayResponse
  },
  {
    path: '/dragonpay/invalid',
    name: 'DragonPayInvalid',
    component: DragonPayInvalid
  },
  {
    path: '/dragonpay/success',
    name: 'DragonPaySuccess',
    component: DragonPaySuccess
  },
  {
    path: '/dragonpay/failed',
    name: 'DragonPayFailed',
    component: DragonPayFailed
  },
  {
    path: '/dragonpay/pending',
    name: 'DragonPayPending',
    component: DragonPayPending
  }
]


const router = new vueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
