<template>
    <v-container class="fill-height fill-width" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                    <v-img
                      src="/SSCR.png"
                      alt="San Sebastian College-Recoletos"
                      contain
                      height="150"
                      class="mt-5 mb-2"
                    ></v-img>
                    <v-card-text class="mb-15">
                    <v-divider class="linedivider my-5"></v-divider>
                    <div class="text-center" v-if="err">
                        <h1>{{ message }}</h1>
                    </div>
                    <div class="text-center" v-if="!err">
                        <h2 class="mb-5 mt-5">{{ message }}</h2>
                          <v-btn 
                          class="font-weight-bold"
                          color="orange darken-2"
                          dark
                          x-large
                          @click="Login"
                        >Login</v-btn>
                    </div>
                    <v-divider class="linedivider my-5"></v-divider>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import studentService from '@/services/student'
export default {
    name: 'Verifyemail',
    data: () => ({
        message: '',
        err: false
    }),
    created: async function () {
        try {
            const response = await studentService.VerifyStudentEmail({
                token : this.$route.params.token
            })    
            console.log(response)
            this.err = false
            this.message = response.data.message

        } catch (error) {
            this.err = true
            this.message = error.response.data.error
        }
    },
    methods: {
        Login () {
            this.$router.push('/')
        }
    }
}
</script>
<style scoped>

</style>