<template>
    <div>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                    <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="200" class="mt-5 mb-2">
                    </v-img>
                    <v-divider class="linedivider my-10"></v-divider>
                    <v-card-text class="text-center">
                        <h2 class="my-2 font-weight-black dark">{{ message }}</h2>
                    </v-card-text>
                    <v-card-actions>
                        <v-divider class="linedivider my-5 text-center"></v-divider>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import studentService from '@/services/student'

export default {
    name: 'DragonPayResponse',
    data: () => ({
        txnid: '',
        remarks: '',
        refno: '',
        status: '',
        digest: '',
        message: '',
    }),
    mounted (){
        this.SetArgs()
        this.CheckDigest()
    },
    methods: {
        SetArgs() {
            let urlParams = new URLSearchParams(window.location.search)
            this.txnid = urlParams.get('txnid')
            this.remarks = urlParams.get('message')
            this.refno = urlParams.get('refno')
            this.status = urlParams.get('status')
            this.digest = urlParams.get('digest')

        },
        async CheckDigest() {
            try {
                const result = await studentService.CheckDpayDigest({
                    txnid : this.txnid,
                    remarks: this.remarks,
                    refno: this.refno,
                    status: this.status,
                    digest: this.digest
                }).then((res) => {
                    console.log(res)
                }).catch((e) => { console.log(e) })
                // console.log(result)
                // if (result.status === 302) {
                //     this.$router.push({ name: 'Home' })
                //     console.log(result)
                //     switch (result.data.status) {
                //         case 'S':
                //             this.message = 'Your Transaction successfully completed.'
                //             break;
                //         case 'P':
                //             this.message = 'Your Transaction is pending please check your email.'
                //             break;
                //         case 'F':
                //             this.message = 'Your Transaction failed please try again.'
                //             break;
                //         case 'X':
                //             this.message = 'Invalid transaction! please try again.'
                //         default:
                //             break;
                //     }
                // }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>