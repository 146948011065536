import axios from "axios";
import axiosCookieJarSupport from 'axios-cookiejar-support'
import tough from 'tough-cookie'


//axiosCookieJarSupport(axios)

const cookiejar = new tough.CookieJar()

export default () => {
    return axios.create({
        baseURL: `http://portal.sscrmnl.edu.ph:4488/`,
        //baseURL: process.env.VUE_APP_BASE_URL,
        jar: cookiejar,
        withCredentials: true,
        headers:{'Content-Type': 'application/json'}
    })
}