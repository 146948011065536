<template>
  <div>
    <v-container>
        <v-row dense>
            <v-col cols=12 xl="5" lg="5" md="5" xs="12" sm="12">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFrom"
                        label="Date From"
                        hint="MM/DD/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="ddateFrom = parseDate(dateFrom)"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="ddateFrom"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols=12 xl="5" lg="5" md="5" xs="12" sm="12">
                <v-menu
                    ref="menu1"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateTo"
                        label="Date To"
                        hint="MM/DD/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="ddateTo = parseDate(dateTo)"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="ddateTo"
                        no-title
                        @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols=12 xl="2" lg="2" md="2" xs="12" sm="12" class="centerbtn">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="orange darken-2 dark" @click="getStudentLogs" v-bind="attrs" v-on="on">
                            <v-icon color="white">
                                mdi-file-document-multiple-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Get Student Logs</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <v-data-table :headers="logs" :items="studentLogs" class="elevation-1" hide-default-footer>
                    <template v-for="header in logs.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )" v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>STUDENT IN-OUT LOGS</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import studentService from '@/services/student'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'StudentLogs',
    props: ['student', 'refresh'],
    data: vm => ({
        studentLogs: [],
        dateFrom: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        dateTo: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        ddateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        ddateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
    }),
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
        logs() {
            return [
                { text: 'ROW', align: 'start', 'sortable': false, value: 'row' },
                { text: 'Station ID', value: 'stationId', align: 'center' },
                { text: 'Log Date', value: 'logDate', align: 'center', class: 'mr-1' },
                { text: 'In-Out Logs', value: 'inoutLog', align: 'center', class: 'mr-1' },
            ]
        },
    },

    watch: {
        ddateFrom(val) {
            this.dateFrom = this.formatDate(this.ddateFrom)
        },
        ddateTo(val) {
            this.dateTo = this.formatDate(this.ddateTo)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        
        async getStudentLogs() {

            try {
                let data = []
                const timein = '00:00:00'
                const timeout = '23:59:59'
                const dateTimeIn = moment(this.parseDate(this.dateFrom) + ' ' + timein).format('YYYY-MM-DD HH:mm:ss')
                const dateTimeOut = moment(this.parseDate(this.dateTo) + ' ' + timeout).format('YYYY-MM-DD HH:mm:ss')
                const result = await studentService.GetStudentLogs({
                    studentNo: this.student.stud_no,
                    dateFrom: dateTimeIn,
                    dateTo: dateTimeOut,
                })
                if (result.status === 200) {
                    this.studentLogs = []
                   result.data.result.forEach((value,index) => {
                        data = []    
                        data =  {
                            row: index + 1,
                            stationId: value.station_id, 
                            logDate: moment(value.log_dt).format('MM/DD/YYYY hh:mm:ss A') ,
                            inoutLog: value.in_out_log === 'I' ? 'IN' : 'OUT',
                        }
                        this.studentLogs.push(data)
                   })
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
}
</script>

<style scoped>
.centerbtn {
    margin: auto;
    text-align: center;
}
</style>