import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    student: [],
  },
  mutations: {
    setStudent(state, payload) {
      state.student = payload
    }
  },
  actions: {
    setStudent({ commit }, payload) {
      commit('setStudent', payload)
    }
  },
  modules: {
  },
  getters: {
    getStudent(state) {
      return state.student
    }
  }
})
