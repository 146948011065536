<template>
    <div class="ma-5 mt-8">
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field label="Term" v-model="term" type="text" class="font-weight-bold uppcase" outlined
                    autocapitalize readonly></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"></v-col>
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                <v-btn class="font-weight-bold" color="orange darken-2" dark x-large @click="print">
                    Study Load
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-autocomplete label="Curricular Program / Course" class="font-weight-bold" outlined v-model="program"
                    item-text="program" item-value="program_id" :items="programs" @change="changeProgram"
                    :search-input.sync="search" :return-object="false"
                    :readonly="subject_enrolled.length > 0 ? true : false">
                </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <!-- <v-autocomplete label="Year Level" class="font-weight-bold" outlined v-model="yearlvl"
                    :items="year_level" readonly :return-object="false"></v-autocomplete> -->
                <v-select label="Year Level" class="font-weight-bold" outlined v-model="yearlvl"
                    :items="year_level"></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-show="DownPayment > TotCredits ? true : false">
                <v-alert text type="info" prominent border="left" dense>
                    Encoding of subjects is valid only for {{ validDays }} day(s)
                </v-alert>
            </v-col>
        </v-row>
        <!-- <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-autocomplete
                label="Please Select Subjects"
                class="font-weight-bold"
                outlined
                v-model="subject"
                :items="subjects"
                item-text="subject_desc"
                item-value="subj_id"
                :return-object="false"
                multiple
            ></v-autocomplete>
        </v-col>
    </v-row> -->
        <v-row dense class="mb-8">
            <v-col>
                <v-data-table :headers="headers" mobile-breakpoint="600" :items="subject_enrolled" class="elevation-1"
                    hide-default-footer disable-pagination>
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Subjects Enrolled</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn v-if="BtnProgram" class="font-weight-bold" color="orange darken-2" dark large
                                @click="GetBlocks" v-show="DownPayment > TotCredits ? true : false">
                                Set Block Section
                            </v-btn>
                            <v-btn v-else class="font-weight-bold" color="orange darken-2" dark x-large
                                @click="GetSubjects" v-show="DownPayment > TotCredits ? true : false">
                                Add Subjects
                            </v-btn>

                            <v-dialog v-model="SubjectDialog" max-width="1800px">
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row dense no-gutters>
                                                <v-col cols="12">
                                                    <v-card>
                                                        <v-card-title>
                                                            <v-text-field v-model="searchsubject"
                                                                append-icon="mdi-magnify" label="Search Subject"
                                                                single-line hide-details></v-text-field>
                                                        </v-card-title>
                                                    </v-card>
                                                    <v-data-table v-model="selected" :headers="Subject_Headers"
                                                        :items="subject_offered" :search="searchsubject" single-select
                                                        class="elevation-1" show-select item-key="row"
                                                        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
                                                        :items-per-page="10" @click:row="selectRow">
                                                        <template v-slot:item.schedule="{ item }">
                                                            <td v-html="item.schedule">{{ item.schedule }}</td>
                                                        </template>

                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="pb-5">
                                        <v-spacer></v-spacer>
                                        <v-btn depressed disabled class="font-weight-bold" color="orange darken-2" dark
                                            x-large @click="SetSubjects">
                                            Set Subjects
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Are you sure you remove this subject?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-if="!BtnProgram" v-slot:item.schedule="{ item }">
                        <td v-html="item.schedule">{{ item.schedule }}</td>
                    </template>
                    <template v-if="!BtnProgram" v-slot:item.actions="{ item }">
                        <v-icon large @click=" deleteSubject(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-if="!BtnProgram" v-slot:body.append>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right">Total Credits:</td>
                            <td class="text-start">{{ sumCredit }}</td>
                            <td></td>
                        </tr>
                    </template>
                    <template v-else v-slot:body.append>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right">Total Credits:</td>
                            <td class="text-start">{{ sumCredit }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="5" class="text-left">
                        <v-subheader class="font-weight-black text-md-body-1">Back Account/Overpayment:</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="back_account">
                        </v-text-field>
                    </v-col>
                </v-row>

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Tuition:</v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="total_tuition">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Total Fees:</v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="total_fees">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Miscellaneous Fee:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="total_misc">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Other Fees:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="other_fees">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Other Charges:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="other_charges">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Energy Fee:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="energy_fee">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Laboratory Fee:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="laboratory_fee">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Total Credits:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="total_credits">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row dense>
                    <v-col cols="4" class="text-right">
                        <v-subheader class="font-weight-black text-md-body-1 text-right">Balance:
                        </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field class="font-weight-black" reverse readonly placeholder="0.00"
                            v-model="total_balance">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" class="justify-start text-left">
                <v-subheader class="font-weight-bold justify-start">
                    <span class="font-weight-bold justify-start notify-bg pa-5 text-md-body-1">
                        Note:"( )" for Over Payment
                    </span>


                </v-subheader>
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="justify-end text-right">
                <v-btn class="font-weight-bold justify-center ma-4 " color="orange darken-2" dark x-large
                    @click="payment">
                    <v-icon left>
                        mdi-credit-card-outline
                    </v-icon>
                    Pay
                </v-btn>
                <v-subheader class="font-weight-bold justify-end">
                    <span class="font-weight-bold justify-end notify-bg  pa-2">
                        Through Dragon Pay
                    </span>


                </v-subheader>
            </v-col>
        </v-row>

        <v-dialog v-model="ConflictDialog" max-width="550px">
            <v-card>
                <v-card-title class="text-h6 justify-center">{{ DialogMessage }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div hidden>
            <!-- SOURCE -->
            <div id="printMe">
                <div class="title">
                    <img src="/SSCR.png" alt="San Sebastian College-Recoletos" height="75"
                        class="logo-study-load" />
                    <span>San Sebastian College-Recoletos</span>
                </div>
                <div class="sub-title">
                    <span>Claro M. Recto Ave., Manila, Philippines</span>
                </div>

                <div class="study-load-title">
                    <span>{{ StudyLoad }}STUDY LOAD</span>

                </div>
                <div class="study-load-sub-title">
                    <span><u>STUDENT'S COPY</u></span>
                </div>
                <div class="row">
                    <div class="column cols6"></div>
                    <div class="column cols4">
                        <span>Enrollment Date : </span> <span class="ml-1">{{ studDateEnrolled }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Student Number:</span><span class="ml-1"><b> {{ student.stud_no }} </b></span>
                    </div>
                    <div class="column cols4">
                        <span> Semester/School Year:</span><span class="ml-1">{{ term }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Name: </span><span class="ml-1">{{ full_name }}</span>
                    </div>
                    <div class="column cols4">
                        <span> Admission Status:</span><span class="ml-1">Freshman</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Course & Year:</span><span class="ml-1">{{ studCourseYear }}</span>
                    </div>
                    <div class="column cols4">
                        <span>Gender:</span><span class="ml-1 mr-1">{{ studGender }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Section:</span><span class="ml-1">{{ studSection }}</span>
                    </div>
                    <div class="column cols4">
                        <span>Age:</span><span class="ml-1 mr-1"> {{ studAge }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Home Address:</span><span class="ml-1">{{ studAddress }}</span>
                    </div>
                    <div class="column cols4">
                        <span> Phone: </span><span class="ml-1">{{ student.home_tel_no }}</span>
                    </div>
                </div>
                <v-row dense class="mb-8">
                    <v-col>
                        <table>
                            <thead>
                                <tr class="height-10">
                                    <th class="text-center-no-margin height-10 cols1-2">Subject Code</th>
                                    <th class="text-center-no-margin height-10 cols1">Faculty Name</th>
                                    <th class="text-center-no-margin height-10 cols2">Descriptive Title</th>
                                    <th class="text-center-no-margin height-10 cols2">Schedule</th>
                                    <th class="text-center-no-margin height-10 cols1">Room</th>
                                    <th class="text-center-no-margin height-10 cols1">Offer No</th>
                                    <th class="text-center-no-margin height-10 cols1">Units</th>
                                    <th class="text-center-no-margin height-10 cols1">Laboratory Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="subject in subject_enrolled" :key="subject.subj_no" class="height-10">
                                    <td class="height-10 caption">{{ subject.subj_no }}</td>
                                    <td class="height-10 caption">{{ subject.faculty_name }}</td>
                                    <td class="height-10 caption">{{ subject.subj_title }}</td>
                                    <td class="height-10 text-center-no-margin caption">{{ subject.schedule }}</td>
                                    <td class="height-10 text-center-no-margin caption">{{ subject.room_no }}</td>
                                    <td class="height-10 text-center-no-margin caption">{{ subject.offer_no }}</td>
                                    <td class="height-10 text-center-no-margin caption">{{ subject.units }}</td>
                                    <td class="height-10 text-right caption mr-2">{{ subject.lab_fee }}</td>
                                </tr>
                                <tr class="height-10">
                                    <td colspan="6" class="height-10 text-right-no-margin footer-font">Total:</td>
                                    <td class="height-10 text-center-no-margin footer-font">{{ sumCredit }}</td>
                                    <td class="height-10 text-right footer-font mr-2">{{ laboratory_fee }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <v-data-table :headers="PrintHeaders" :items="subject_enrolled" class="elevation-1"
                            hide-default-footer dense disable-pagination>
                            <template v-for="header in PrintHeaders.filter((header) =>
                                header.hasOwnProperty('formatter')
                            )" v-slot:[`item.${header.value}`]="{ header, value }">
                                {{ header.formatter(value) }}
                            </template>
                            <template v-slot:item.schedule="{ item }">
                                <td v-html="item.schedule">{{ item.schedule }}</td>
                            </template>
                            <template v-slot:body.append>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="text-right">Total: </td>
                                    <td class="text-start"><b>{{ sumCredit }}</b></td>
                                    <td class="text-right"><b>{{ laboratory_fee }}</b></td>
                                </tr>
                            </template>
                        </v-data-table> -->
                    </v-col>
                </v-row>
                <div class="row">
                    <span class="ml-1 text-10">I shall abide by all the rules and regulations now enforced
                        or may be promulgated by San Sebastian College-Recoletos from time to time.
                        Likewise, I agree to the cancellation of the credits i have earned in subjects i have
                        enrolled under false pretenses.</span>
                </div>
                <div class="row mt-1">
                    <div class="column cols4">
                        <span class="text-10"><b>SAS ASSESSMENT</b></span>
                    </div>
                    <div class="column cols4">
                        <span class="text-10"><b>TOTAL TUITION & FEES</b></span>
                    </div>
                    <div class="column cols2 text-right text-10">
                        <span class="mr-1"><b>{{ this.total_fees }}</b></span>
                    </div>
                </div>
                <div class="cols4">
                    <table class="no-border cols5">
                        <tr class="no-border">
                            <td class="cols7 height-10">Back Account</td>
                            <td class="height-10" style="width:5%">:</td>
                            <td class="cols2-1 text-right height-10">{{ back_account }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="cols7 height-10">Total Tuition Fees ({{ sumCredit }})</td>
                            <td class="height-10" style="width:5%">:</td>
                            <td class="cols2-1 text-right height-10">{{ total_tuition }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Miscellaneous Fees</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ total_misc }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Other Fees</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ other_fees }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Energy Fees</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ energy_fee }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Laboratory Fees</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ laboratory_fee }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Tuition & Fees</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ total_fees }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10">Total Credits</td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10">{{ total_credits }}</td>
                        </tr>
                        <tr class="no-border">
                            <td class="height-10"><b>Total Balance</b></td>
                            <td class="height-10">:</td>
                            <td class="text-right height-10"><b>{{ total_balance }}</b></td>
                        </tr>
                    </table>
                </div>
                <div class="row">
                    <div class="column cols1">
                        <span></span>
                    </div>
                    <div class="column cols5">
                        <!-- <table class="no-border cols5">
                            <tr class="no-border">
                                <td>Mode of Payment</td>
                                <td>:</td>
                                <td class="text-right">INSTALLMENT</td>
                            </tr>
                            <tr class="no-border">
                                <td>DownPayment</td>
                                <td>:</td>
                                <td class="text-right">0.00</td>
                            </tr>
                            <tr class="no-border">
                                <td>Date</td>
                                <td>:</td>
                                <td class="text-right">0.00</td>
                            </tr>
                            <tr class="no-border">
                                <td>Date</td>
                                <td>:</td>
                                <td class="text-right">0.00</td>
                            </tr>
                            <tr class="no-border">
                                <td>Date</td>
                                <td>:</td>
                                <td class="text-right">0.00</td>
                            </tr>
                        </table>
                        <div>
                            <h4 class="text-center textbold"><u>P</u> <u>A</u> <u>Y</u> <u>M</u> <u>E</u> <u>N</u>
                                <u>T</u> <u>S</u>
                            </h4>
                        </div> -->
                    </div>
                </div>
                <div class="text-center no-pads">
                    <span class="text-10"><u>I M P O R T A N T</u></span>
                </div>
                <div class="text-center no-pads">
                    <span cass="text-10">OFFICIAL STUDY LOAD - means officialy enrolled subjects and it will serve as
                        an admission slip to the classrom.</span>
                </div>
                <div class="text-center no-pads">
                    <span class="text-10"><u>* CHARGES TO STUDENTS WITHDRAWING/DROPPING *</u></span>
                </div>

                <div>
                    <span class="text-10">
                        When a student registers in the school, it is understood that he is enrolling for the entire
                        semester.
                    </span>
                </div>
                <div class="indent-text">
                    <span class="text-10">
                        A student who transfers or otherwise withdraws in writing within the first week, will be charged
                        ten percent(10%) of the total amount or the total miscellaneous and other fees, whichever is
                        higher or twenty percent(20%) or the total miscellaneous and other fees, whichever is higher, if
                        within the second week of classes, regardless of whether or not he has actually attended
                        classes. The student will be charged all the school fees in full if he withdraws anytime after
                        the second week of classes.
                    </span>
                </div>
                <div class="indent-text mt-3">
                    <span class="text-10">
                        However, students withdrawing or dropping subjects at anytime during summer term, whether
                        officially or unofficially shall be charged 100% of the total amount due.
                    </span>
                </div>
                <br>
                <br>
                <div class="row no-pads2" style="height: 20%;">
                    <div class="column cols3 text-center">
                        <br>
                        <span>______________________________</span>
                        <br>
                        <span>ID Section</span>
                    </div>
                    <div class="column cols4 text-center mt-3">
                        <div>
                            <span>{{ registrar }}</span>
                        </div>
                        <div>
                            <span class="underline">_______________________________</span>
                        </div>
                        <div>
                            <span>Registration Releasing Officer</span>
                        </div>
                        <div>
                            <span>(Signature Over Printed Name) </span>
                        </div>
                    </div>
                    <div class="column cols3 text-center">
                        <br>
                        <span>__________________________</span>
                        <br>
                        <span>Student Signature</span>
                        <br>
                        <span>(Signature Over Printed Name)</span>
                    </div>
                </div>
                <br>
                <div class="row no-pads2" style="height: 20%;">
                    <div class="column cols5">
                        Date & Time Printed : {{ today }}
                    </div>
                    <div class="column cols5 text-right">
                        <span class="text-right">
                            FO-REG-284; Revision 08; April 29, 2013
                        </span>
                    </div>
                </div>
                <br />
                <div class="row no-pads2 mt-1" style="height: 20%">
                    <div class="column cols5">
                        <span>This study load is deemed official when duly signed by the registrar.</span>
                    </div>
                    <div class="column cols5 text-right">
                        <span class="text-right">This study load was generated via student portal.</span>
                    </div>
                </div>
                <div class="row no-pads2 mt-1" style="height: 20%">
                    <div class="column cols5">
                        <span>SSC-R Email address: <strong>{{ studEmail }}</strong></span>
                    </div>
                    <div class="column cols5">
                        <span>Default Password: <strong>gobaste123</strong></span>
                    </div>
                </div>
                <div class="row no-pads2 mt-1" style="height: 20%">
                    <div class="columns cols10">
                        <span>
                            Note: Please wait 24 hours to activate your school email address. To check please Login
                            your email account at: http://mail.sscrmnl.edu.ph, Old student with active working email can
                            disregard the default password.
                        </span>
                    </div>
                </div>
                <div class="mt-1 mb-1">
                    <hr style="border: 1px dashed black;" />
                </div>
                <div>
                    <h3 class="text-center">STUDENT AFFAIRS OFFICE</h3>
                </div>
                <div>
                    <h3 class="text-center mb-1"><u>UNDERTAKING</u></h3>
                </div>

                <div class="indent-text">
                    <span>
                        I, <b>{{ full_name }}, {{ studCourseYear }}</b>, do hereby state that I am NOT A MEMBER OF ANY
                        FRATERNITY/SORORITY or any UNDERGROUND OR ILLEGAL ORGANIZATION OR AGGRUPATION THAT ADVOCATES
                        VIOLENCE. ERODES THE VALUES AND TEACHINGS OF CATHOLIC CHURCH AND THE SCHOOL, and/ or UNDERMINES
                        THE
                        SAFETY AND SECURITY OF THE SCHOOL AND IT'S STUDENTS AND EMPLOYEES.
                    </span>
                </div>

                <div class="indent-text mt-1">
                    <span>
                        I also state that I shall abide with all school policies and regulations and shall subject
                        myself to
                        disciplinary procedure in accordance with the School's existing Student Handbook should I commit
                        any
                        violation as prescribed in the said Student Handbook and other duly issued policies.
                    </span>

                </div>
                <div class="indent-text mt-1">
                    <span>
                        I am executing this Undertaking as a necessary condition and/ or requisite for my admission and
                        continuance of study in San Sebastian College-Recoletos-Manila. I fully understand that I should
                        commit any violation of this Undertaking, I shall be disqualified for admission/ readmission
                        without
                        prejudice to the right of the School to initiate <b>CRIMINAL, CIVIL and/ or ADMINISTRATIVE</b>
                        charges against me.
                    </span>
                </div>
                <div class="indent-text mt-1">
                    <span>
                        Done this <b>{{ dday }}</b> day of <b>{{ mmonth }}</b>, <b>{{ yyear }}</b> at San Sebastian
                        College-Recoletos, Claro M. Recto Ave., Manila, Philippines.
                    </span>
                </div>
                <div class="row">
                    <div class="column cols5"></div>
                    <div class="column cols5 text-right">
                        <h4>With my conformity (Parents and/ or Guardian)</h4>
                    </div>
                </div>

            </div>
            <!-- OUTPUT -->
        </div>
        <v-dialog v-model="PaymentDialog" max-width="550px">
            <v-card>
                <v-card-title class="text-h6 justify-center">Down Payment
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-alert v-show="PayErrorMessageShow" type="error" dark text>{{ PayErrorMessage }}</v-alert>
                    <v-text-field label="Please Enter an Amount" outlined placeholder="0.00" v-model="AmountPayment"
                        @keypress="filter()" @change="formatNumber()" class="font-weight-bold"
                        hint="A 20 pesos charge will be added as DragonPay service fee" persistent-hint>
                    </v-text-field>
                    <v-alert type="info" dark text>Note: Minimum Down Payment is {{ formatExample(DownPayment) }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" dark @click="PaymentDialog = false" class="ma-3 pa-3 white--text">
                        <v-icon left>
                            mdi-close
                        </v-icon>
                        Close
                    </v-btn>
                    <v-btn color="orange darken-2" class="ma-2 white--text" dark elevation="2" @click="ToPay()">
                        <v-icon left>
                            mdi-credit-card-outline
                        </v-icon>
                        Pay
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="BlockDialog" max-width="550px">
            <v-card>
                <v-card-title class="text-h6 justify-center">Please Select Block Section</v-card-title>
                <v-card-text>
                    <v-autocomplete label="Select Block Section" class="font-weight-bold" outlined
                        v-model="BlockSection" item-text="block_code" item-value="block_code" :items="BlockSections"
                        :search-input.sync="searchBlockSection" :return-object="false">
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" dark @click="BlockDialog = false" class="ma-3 pa-3 white--text">
                        <v-icon left>
                            mdi-close
                        </v-icon>
                        Close
                    </v-btn>
                    <v-btn depressed disabled color="orange darken-2" class="ma-2 white--text" dark elevation="2"
                        @click="SelectBlockCode">
                        <v-icon left>
                            mdi-select
                        </v-icon>
                        Select Block Code
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import studentService from '@/services/student'
import { extendMoment } from 'moment-range'
import moment from 'moment'
import numeral from 'numeral'
import _ from 'lodash'
import printjs from 'print-js'


export default {
    name: 'Enrollment',
    props: ['student','cnflag'],
    data: () => ({
        AmountPayment: '',
        PaymentDialog: false,
        output: null,
        program: '',
        dialogDelete: false,
        termcd: '',
        programs: [],
        term: '',
        terms: [],
        deletedIndex: '',
        deletedSubject: [],
        search:'',
        searchBlockSection:'',
        year_level: ['1','2','3','4'],
        yearlvl:'',
        dateEnrolled: '',
        subject:'',
        subjects:[],
        SubjectDialog: false,
        ConflictDialog: false,
        searchsubject:'',
        DialogMessage: '',
        totalCredit: 0,
        sumCredit: 0,
        headers: [
            {
                text: 'ROW',
                align: 'start',
                sortable: false,
                value: 'row'
            },
            { text: 'Offer No',value: 'offer_no' },
            { text: 'Faculty Name', value: 'faculty_name' },
            { text: 'Subject No', value: 'subj_no' },
            { text: 'Subject Title', value: 'subj_title'},
            { text: 'Schedule', value: 'schedule' },
            { text: 'Units', value: 'units' },
            { text: 'Credit Effect', value: 'credit_effect'},
            { text: 'Action' , value: 'actions', align: ' d-none', sortable: false },
            { text: 'Days', value: 'days', align: ' d-none'},
            { text: 'Time Tm', value: 'time_tm', align: ' d-none'},
            { text: 'Duration', value: 'duration', align: ' d-none'},
            { text: 'Subject ID', value: 'subj_id', align: ' d-none'}
        ],
        subject_enrolled: [],
        editedIndex: -1,
        Subject_Headers: [
            { text: 'Index', align: ' d-none', value: 'row'},
            { text: 'Offer No.', align: 'start', sortable: true, value: 'offer_no' },
            { text: 'Faculty Name', value: 'faculty_name' },
            { text: 'Subject No.', value: 'subj_no' },
            { text: 'Subject Title', value: 'subj_title' },
            { text: 'Credit Units', value: 'credit_units' },
            { text: 'Schedule', value: 'schedule' },
            { text: 'Room', value: 'room_no'},
            { text: 'Subject ID', value: 'subj_id', align: ' d-none'},
            { text: 'Term', value: 'term_cd', align: ' d-none'},
            { text: 'Day', value: 'days', align: ' d-none'},
            { text: 'Time tm', value: 'time_tm', align: ' d-none'},
            { text: 'Duration', value: 'duration', align: ' d-none'}

            
        ],
        subject_offered: [],
        selected: [],
        rowsPerPageItems: [10, 20, 30, 40],
        pagination: {
            rowsPerPage: 20
        },
        total_tuition: 0,
        back_account: 0,
        total_fees: 0,
        total_misc: 0,
        other_fees: 0,
        other_charges: 0,
        energy_fee: 0,
        laboratory_fee: 0,
        total_credits: 0,
        total_balance: 0,
        full_name: '',
        studAge:'',
        studGender: '',
        studStatus: '',
        studCourseYear: '',
        studDateEnrolled: '',
        studAddress: '',
        studEmail: '', 
        today: '',
        mmonth: '',
        yyear: '',
        dday: '',
        DownPayment: 0,
        TotCredits: 0 ,
        StudyLoad: '',
        windowHeight: '',
        PayErrorMessage: '',
        PayErrorMessageShow: false,
        DragonPay: process.env.VUE_APP_DRAGONPAY_URL,
        BtnProgram: false,
        BlockDialog: false,
        BlockSection: '',
        BlockSections: [],
        BackAccountAmount: 0,
        studSection: '',
        validDays: 0,
        presentYear: '',
        EndTerm: false,
        registrar: '',
    }),
    // created() {
    //     // this.GetProgramOffer()
    //     console.log('test')
    // },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Select Subjects' : 'Edit Item'
      },
        PrintHeaders () {
            return [
                { text: 'Subject Code', value: 'subj_no' ,class: 'height-10' },
                { text: 'Faculty Name', value: 'faculty_name' ,class: 'height-10' },
                { text: 'Descriptive Title', value: 'subj_title' ,class: 'height-10' },
                { text: 'Schedule', value: 'schedule' ,class: 'height-10' },
                { text: 'Room', value: 'room_no' ,class: 'height-10' },
                { text: 'Offer No', value: 'offer_no' ,class: 'height-10' },
                { text: 'Units', value: 'units' ,class: 'height-10' },
                { text: 'Laboratory Fee', value: 'lab_fee', formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: "mr-1 height-10"}

            ]
        }
    },

    mounted() {
        this.GetCurricularProgram()
        this.SetValidDays()                                                                                                                                                                                                              
        this.SetEnrolledAccount()
        this.GetBackAccount()
        this.SetTime()
        this.SetRegistrar()
        // this.GetEndTerm()
        window.addEventListener('resize', () => {
            this.windowHeight = window.innerWidth
            this.SetHeader(this.windowHeight)
        })
    },
    watch: {

    },
    methods: {
        async SetRegistrar() {
            try {
                const result =  await studentService.GetRegistrar();
                if (result.status === 200) {
                    this.registrar = result.data.result;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async GetEndTerm() {    
            try {
                const result = await studentService.GetEndTerm(this.termcd)
                 if (result.status === 200) {
                   if(result.data.result.length > 0) {
                     const DateToday = new Date()
                     let today = moment(DateToday).format('MM/DD/YYYY')
                     
                     if (moment(result.data.result).isBefore(today) || moment(result.data.result).isSame(today)) {
                        this.EndTerm = true
                     } else {
                        this.EndTerm = false
                     }
                   } else {
                
                    this.EndTerm = false
                   }
                 }
            } catch (error) {
                console.log(error)
            }
        },
        async SetValidDays() {
            try {
                const result = await studentService.GetValidDays()
                this.validDays = result.data.result
            } catch (error) {
                console.log(error)
            }
        },
        async SelectBlockCode() {
            try {
                
                if (this.EndTerm) {
                    this.DialogMessage = 'Online Enrollment has ended please proceed to SSC-R Manila Office for other concerns!'
                    this.ConflictDialog = true
                    return 
                }
                this.BlockDialog = false
                let data = []
                const result = await studentService.GetSubjectsOnBlockCode({
                    student_no: this.student.stud_no,
                    program_id: this.program,
                    term_cd: this.termcd,
                    block_code: this.BlockSection,
                    year_level: this.yearlvl
                })
                if (result.status === 200) {
                    this.subject_enrolled = []
                    this.sumCredit = 0
                    result.data.result.result.forEach((value, index) => {
                            data = {
                                row: index + 1,
                                offer_no: value.offer_no,
                                faculty_name: value.faculty_name,
                                subj_no: value.subj_no,
                                subj_title: value.subj_title,
                                units: value.credit_units,
                            }

                            this.subject_enrolled.push(data)
                            this.sumCredit += value.credit_units

                    })

                    if (this.subject_enrolled.length < 1) {
                        this.dialogDelete = false
                        this.total_tuition = this.formatExample(0.00)
                        this.total_misc = this.formatExample(0.00)
                        this.other_fees = this.formatExample(0.00)
                        this.energy_fee = this.formatExample(0.00)
                        this.laboratory_fee = this.formatExample(0.00)
                        this.total_fees = this.formatExample(0.00)
                        this.other_charges = this.formatExample(0.00)
                        this.total_credits = this.formatExample(0.00)
                        this.total_balance = this.formatExample(0.00)
                    } else {
                        this.dialogDelete = false
                        this.total_tuition = this.formatExample(result.data.result.fee.tuition)
                        this.total_misc = this.formatExample(result.data.result.fee.misc)
                        this.other_fees = this.formatExample(result.data.result.fee.other_fees)
                        this.energy_fee = this.formatExample(result.data.result.fee.energy)
                        this.laboratory_fee = this.formatExample(result.data.result.fee.laboratory)
                        this.total_fees = this.formatExample(result.data.result.fee.total_fees)
                        this.other_charges = this.formatExample(result.data.result.fee.other_charges)
                        this.total_credits = this.formatExample(result.data.result.fee.total_credits)
                        this.TotCredits = result.data.result.fee.total_credits
                        this.total_balance = this.formatExample(((result.data.result.fee.total_fees + result.data.result.fee.other_charges) - result.data.result.fee.total_credits) + this.BackAccountAmount)
                    }

                }
             } catch (error) {
                console.log(error)
            }
        },
        async GetBlocks(){
              if (this.EndTerm) {
                    this.DialogMessage = 'Online Enrollment has ended please proceed to SSC-R Manila Office for other concerns!'
                    this.ConflictDialog = true
                    return 
                }
            this.BlockDialog = true
            const result = await studentService.GetBlockCodes({
                program_id: this.program,
                term_cd: this.termcd
            })
            if (result.status === 200) {
                this.BlockSections = result.data.result
            }
        },
        async changeProgram () {
            try {
                    const result = await studentService.GetCollegeID({
                    program_id: this.program,
                    stud_no: this.student.stud_no,
                    term: this.termcd

                }) 
                if (result.status === 200) {

                    // console.log(result.data)
                    await this.SetTotalCredit()
                    await this.SetTerm(result.data.result.college_id)
                    // console.log(result.data.result)
                    if (result.data.result.college_id === 100) {
                        this.year_level = ['1','2','3','4','5','6']
                        this.BtnProgram = true
                        this.yearlvl = this.GetYearLevel(result.data.result.year_level)
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Units', value: 'units' },
                        ]
                    } else if (result.data.result.college_id === 200) {
                        this.year_level = ['1', '2', '3', '4', '5','6','7','8','9','10','11','12']
                        this.BtnProgram = true
                        this.yearlvl = this.GetYearLevel(this.presentYear)
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Units', value: 'units' },
                        ]
                    } else {
                        this.year_level = ['1','2','3','4','5']     
                        this.yearlvl = this.GetYearLevel(result.data.result.year_level)
                        this.BtnProgram = false
                        this.headers= [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Schedule', value: 'schedule' },
                            { text: 'Units', value: 'units' },
                            { text: 'Credit Effect', value: 'credit_effect' },
                            { text: 'Action', value: 'actions', sortable: false },
                            { text: 'Days', value: 'days', align: ' d-none' },
                            { text: 'Time Tm', value: 'time_tm', align: ' d-none' },
                            { text: 'Duration', value: 'duration', align: ' d-none' },
                            { text: 'Subject ID', value: 'subj_id', align: ' d-none' }
                        ]
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        GetYearLevel (yr_level) {
            // console.log('year level ni: ', yr_level)
            if (yr_level <= 0) {
                return '1'
            // } else if (yr_level >= 4) {
            //     return '4'
            } else {
                return yr_level.toString()
            }
        },
        async ToPay () {
            if (parseFloat(this.AmountPayment.replace(/,/g, '')) < this.DownPayment) {
                this.PayErrorMessage = 'Minimum payment is ' +  this.formatExample(this.DownPayment)
                this.PayErrorMessageShow = true
                return
            }
            this.PayErrorMessageShow = false
            try {
                let amountpay = parseFloat(this.AmountPayment.replace(/,/g, '')) + 20
                const result = await studentService.GetDragonPayUrl({
                    amount: amountpay,
                    student_no: this.student.stud_no,
                    term_cd: this.termcd,
                })
                if (result.status === 200) {
                    this.PaymentDialog = false
                    window.location = result.data.result.url
                    //console.log(result.data.result)

                    // const Dpay = this.DragonPay + '&invoiceno=' +
                    // //window.location = 'https://facebook.com'
                }
            } catch (error) {
                
            }
           

        },
        formatNumber(){
            this.AmountPayment = this.formatExample(this.AmountPayment)
        },
        filter: function () {
     
            let evt =  window.event;
            let expect = evt.target.value.toString() + evt.key.toString();

            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true
            }
        },
        SetTime() {
           setInterval(this.datetime(), 1000);
            
        },
        SetHeader (val) {
            if (this.BtnProgram) {
                this.headers = [
                    {
                        text: 'ROW',
                        align: 'start',
                        sortable: false,
                        value: 'row'
                    },
                    { text: 'Offer No', value: 'offer_no' },
                    { text: 'Faculty Name', value: 'faculty_name' },
                    { text: 'Subject No', value: 'subj_no' },
                    { text: 'Subject Title', value: 'subj_title' },
                    { text: 'Units', value: 'units' },

                ]
               // this.yearlvl = this.GetYearLevel(this.student.last_yr_level)
            } else {
                if (this.DownPayment > this.TotCredits) {
                    if (val > 600) {
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name'},
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Schedule', value: 'schedule' },
                            { text: 'Units', value: 'units' },
                            { text: 'Credit Effect', value: 'credit_effect' },
                            { text: 'Action', value: 'actions', sortable: false },
                            { text: 'Days', value: 'days', align: ' d-none' },
                            { text: 'Time Tm', value: 'time_tm', align: ' d-none' },
                            { text: 'Duration', value: 'duration', align: ' d-none' },
                            { text: 'Subject ID', value: 'subj_id', align: ' d-none' }
                        ]
                    } else {
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Schedule', value: 'schedule' },
                            { text: 'Units', value: 'units' },
                            { text: 'Action', value: 'actions', sortable: false }
        
                        ]
                    }
                    
                } else {
                    if (val > 600 ) {
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Schedule', value: 'schedule' },
                            { text: 'Units', value: 'units' },
                            { text: 'Credit Effect', value: 'credit_effect' },
                            { text: 'Action', value: 'actions', sortable: false, align: ' d-none ddnone', class: 'ddnone' },
                            { text: 'Days', value: 'days', align: ' d-none ddnone', class: 'ddnone' },
                            { text: 'Time Tm', value: 'time_tm', align: ' d-none' },
                            { text: 'Duration', value: 'duration', align: ' d-none' },
                            { text: 'Subject ID', value: 'subj_id', align: ' d-none' }
                        ]
                    } else {
                        this.headers = [
                            {
                                text: 'ROW',
                                align: 'start',
                                sortable: false,
                                value: 'row'
                            },
                            { text: 'Offer No', value: 'offer_no' },
                            { text: 'Faculty Name', value: 'faculty_name' },
                            { text: 'Subject No', value: 'subj_no' },
                            { text: 'Subject Title', value: 'subj_title' },
                            { text: 'Schedule', value: 'schedule' },
                            { text: 'Units', value: 'units' },
                        ]
                    }
                }      
            } 
        },
        datetime() {
            let currdate = new Date()
            this.today =  moment(currdate).format('MMMM DD, YYYY hh:mm A')
        },
        SetPrintOutData () {
 
            let today = new Date()
            let dday = moment(today).format('DD')
            let mmonth = moment(today).format('MMMM')
            let yyear = moment(today).format('YYYY')
            switch (dday.toString().substring(1, 2)) {
                case 1:
                    this.dday = dday.toString() + 'st'
                    break;
                case 2:
                    this.dday = dday.toString() + 'nd'
                    break;
                case 3:
                    this.dday = dday.toString() + 'rd'
                    break;
                default:
                    this.dday = dday.toString() + 'th'
                    break;
            }
            let birthdate = new Date(this.student.birth_date)
            let yrlvl 
            this.mmonth = mmonth
            this.yyear = yyear
            let age = today.getFullYear() - birthdate.getFullYear()
            let month = today.getMonth() - birthdate.getMonth()
            if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                age--;
            }
            if (this.program === 'KINDER') {
                switch (this.yearlvl) {
                    case '1':
                        yrlvl = 'Kinder 1'
                        break;
                    case '2':
                        yrlvl = 'Kinder 2'
                        break;
                    default:
                        break;
                }
            } else if (this.program === 'ELEM') {
                switch (this.yearlvl) {
                    case 'K1':
                        yrlvl = 'Kinder 1'
                        break;
                    case 'K2':
                        yrlvl = 'Kinder 2'
                        break;
                    case '1':
                        yrlvl = 'Grade 1'
                        break;
                    case '2':
                        yrlvl = 'Grade 2'
                        break;
                    case '3':
                        yrlvl = 'Grade 3'
                        break;
                    case '4':
                        yrlvl = 'Grade 4'
                        break;
                    case '5':
                        yrlvl = 'Grade 5'
                        break;
                    case '6':
                        yrlvl = 'Grade 6'
                        break;
                    default:
                        break;
                }
            } else if (this.program === 'HS' || this.program === 'K12') {
                switch (this.yearlvl) {
                    case '7':
                        yrlvl = 'Grade 7'
                        break;
                    case '8':
                        yrlvl = 'Grade 8'
                        break;
                    case '9':
                        yrlvl = 'Grade 9'
                        break;
                    case '10':
                        yrlvl = 'Grade 10'
                        break;
                    case '11':
                        yrlvl = 'Grade 11'
                        break;
                    case '12':
                        yrlvl = 'Grade 12'
                        break;
                    default:
                        break;
                }
            } else {
                switch (parseInt(this.yearlvl)) {
                    case 1:
                        yrlvl = 'First Year'
                        break;
                    case 2:
                        yrlvl = 'Second Year'
                        break;
                    case 3:
                        yrlvl = 'Third Year'
                        break;
                    case 4:
                        yrlvl = 'Fourth Year'
                        break;
                    case 5:
                        yrlvl = 'Fifth Year'
                        break;
                    default:
                        break;
                }
            }
            
            let lastname = _.isNull(this.student.lastname) || _.isEmpty(this.student.lastname) ? '' : this.student.lastname 
            let firstname = _.isNull(this.student.firstname) || _.isEmpty(this.student.firstname) ? '' : this.student.firstname 
            let midname = _.isNull(this.student.midname) || _.isEmpty(this.student.midame) ? '' : this.student.midname.substring(0, 1) + '.'
            this.full_name = lastname + ', ' + firstname + ' ' + midname
            this.studAge = age
            this.studGender = this.student.gender === 'M' ? 'Male' : 'Female'
            this.studCourseYear = this.program + ' - ' + yrlvl
            this.studDateEnrolled = moment(this.dateEnrolled).format('MMMM DD, YYYY')
            let address = _.isNull(this.student.home_addr_street) || _.isEmpty(this.student.home_addr_street) ? '' : this.student.home_addr_street +', '
            let city = _.isNull(this.student.city_addr) || _.isEmpty(this.student.city_addr) ? '' : this.student.city_addr
            this.studAddress = address + city
            this.studEmail = this.student.email_add
            this.StudyLoad = this.DownPayment > this.TotCredits ? 'OFFICIAL ' : 'OFFICIAL '
        },
        print() {
            let currdate = new Date()
            this.today = moment(currdate).format('MMMM DD, YYYY hh:mm A')
            printjs({printable: 'printMe', type: 'html',showModal: true, font_size: "",
                style: ['@page { size: letter; margin: 2mm;} body {margin: 5px 10px 5px 10px;} div {margin:0; padding: 0;} h4 {margin:0}', `
                    * {
                        box-sizing: border-box;
                    }
                    body {
                        font-size: 10px;
                    }
                    div {
                        padding: 0 !important;
                        margin: 0 !important;
                        height: 25% !important;
                    }
                    .caption {
                        font-size: 8px !important;
                    }
                    .footer-font {
                        font-weight: bold;
                        font-size: 14px;
                    }
                    .height-10{
                        height: 5%;
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                    .fl {
                        float: left
                    }
                    .no-pads2{
                        margin-top: 2px !important;
                        padding-top: 10000px !important;
                    }

                    .fr {
                        float: right;
                        font-size: 18px;

                    }
                    br {
                        margin: 0;
                    }
                    .no-pads {
                       margin-bottom: 2px !important;
                       margin-top: 2px !important;
                    }
                    .title {
                        font-family: "Old English Text MT";
                        text-align: center;
                        font-size: 20px !important;
                        padding-top: 0;
                        margin-top: 0;
                        
                    }

                    .sub-title {
                        text-align: center;
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                    }

                    .study-load-title {
                        text-align: center;
                        margin-top: 2px;
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }

                    .study-load-sub-title {
                        text-align: center;
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .promisory-title {
                        text-align: center;
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 800;
                        text-decoration: underline;
                    }
                    .height-10 {
                        height: 10px !important;
                    }
                    .column {
                        float: left;
                        padding: 0;
                        margin: 0;
                    }

                    .left,
                    .right {
                        width: 20%;
                    }

                    .middle {
                        width: 60%;
                    }

                    .row {
                        padding: 0 !important;
                        margin: 0 !important;
                    }

                    .bold {
                        font-weight: 800;
                        font-size: 12px;
                    }

                    .cols1 {
                        width: 10%;
                    }
                    .cols1-2 {
                        width: 7.5%;
                    }
                
                    .cols1-3 {
                        width: 15%;
                    }
                    .cols2 {
                        width: 20%;
                    }

                    .cols2-1 {
                        width: 25%;
                    }

                    .cols3 {
                        width: 30%
                    }

                    .cols4 {
                        width: 40%;
                    }

                    .cols4-1 {
                        width: 35%;
                    }

                    .cols5 {
                        width: 50%;
                    }

                    .cols6 {
                        width: 60%;
                    }

                    .cols7 {
                        width: 70%;
                    }

                    .cols10 {
                        width: 100%;
                    }

                    .ml-1 {
                        margin-left: 10px;
                    }

                    .mt-1 {
                        margin-top: 10px;
                    }
                    .mb-1 {
                        margin-bottom: 10px;
                    }

                    .ml-2 {
                        margin-left: 20px;
                    }
                    .mr-1 {
                        margin-right: 5px;
                    }

                    .mr-2 {
                        margin-right: 20px;
                    }

                    .mt-2 {
                        margin-top: 20px;
                    }

                    .mb-2 {
                        margin-bottom: 20px;
                    }

                    .ml-3 {
                        margin-left: 30px;
                    }

                    .mr-3 {
                        margin-right: 30px;
                    }

                    .mt-3 {
                        margin-top: 30px;
                    }

                    .mb-3 {
                        margin-bottom: 30px;
                    }

                    .ml-4 {
                        margin-left: 40px;
                    }

                    .mr-4 {
                        margin-right: 40px;
                    }

                    .mt-4 {
                        margin-top: 40px;
                    }

                    .mb-4 {
                        margin-bottom: 40px;
                    }

                    .ml-5 {
                        margin-left: 50px;
                    }

                    .mr-5 {
                        margin-right: 50px;
                    }

                    .mt-5 {
                        margin-top: 50px;
                    }

                    .mb-5 {
                        margin-bottom: 50px;
                    }

                    .text-right {
                        text-align: right !important;
                        font-size: 10px;
                    }
                    .text-right-no-margin {
                        text-align: right !important;
                        font-size: 9px;
                        margin: 0;
                    }

                    .text-center {
                        margin: 5px 0;
                        text-align: center !important;
                        font-size: 10px;
                    }
                    .text-center-no-margin {
                        margin: 0;
                        text-align: center !important;
                        font-size: 9px;
                    }

                    .notes {
                        font-size: 10px;
                    }

                    table {
                        font-size: 10px;
                        width: 100%;
                        height: 10%;
                        padding: 0 10px 0 0  !important;
                        margin: 0 10px 0 0 !important;

                    }
                    th {
                        padding: 0 !important;
                        margin:0 !important;
                        height:10% !important;
                    }

                     tr {
                        height: 1% !important;
                    }
                    table,
                    th,
                    tr {
                        border: 1px solid black !important;
                        border-collapse: collapse;
                    }
                  
                    table tr td:nth-child(2) {
                        text-align: left;
                    }

                     table tr td:nth-child(3) {
                        text-align: left;
                    }

                    table tr td:nth-child(7) {
                        margin-right: 5px !important;
                    }

                   
                    tr, td {
                        text-align: center;
                        padding: 0 10px 0 0  !important;
                        margin: 0 10px 0 0 !important;
                        
                    }

                    .indent-text {
                        text-indent: 20px;
                    }

                    .text-10 {
                        font-size: 10px;
                    }

                    .no-border
                    {
                        border: none !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        height: 2px !important;
                    }
                    .no-border tr {
                        padding: 0 !important;
                        margin: 0 !important;
                    }
                    .no-border td {
                        padding: 0 !important;
                        margin: 0 !important;
                    }

                    .no-border td:nth-child(1) {
                        text-align: left !important;
                    }

                    .textbold {
                        font-weight: bold;
                    }
                    .logo-study-load {
                      position: absolute;
                      left: 100px;
                      
                    }
                    @media print {
                        p {page-break-after: always;}
                    }    `,
                ],  
            })
        },                                                                                                                                                  
        formatExample(value) {

                if (value  >= 0) {
                    return numeral(value).format('0,0.00')
                } else {
                    if (typeof value !== 'string') {
                        value = value * -1

                        return '(' + numeral(value).format('0,0.00') + ')'
                    } else {
                        return numeral(value).format('0,0.00')
                    }
                }
    
            
        },
        async GetBackAccount() {
            await this.SetTerm(this.student.program_id)
            const result = await studentService.GetBackAccount({
                student_no: this.student.stud_no,
                term: this.termcd
            })

            if (result.status === 200) {
                this.back_account = this.formatExample(result.data.result[0].back_account)
                this.BackAccountAmount = result.data.result[0].back_account
            }
        },
        async SetTotalCredit() {
            await this.SetTerm(this.student.program_id)
           const tterm =  this.termcd.substring(4, 6)
            
           if (tterm === '23'){
               this.totalCredit = 9
           } else {
               this.totalCredit = await this.GetTotalCredits(this.yearlvl, tterm, this.program )
           }
        },
        async GetTotalCredits(yearlvl, tterm, program) {
            try {
                const result = await studentService.GetTotalCredits({
                    yearlvl: yearlvl,
                    tterm: tterm,
                    program: program
                })

                if (result.status === 200) {
                    return result.data.result
                } else {
                    return 0
                }
            } catch (e) {
                console.log(e)
            }
        },
        selectRow(value ,row) {
             if (row.isSelected) row.select(false);
             else row.select(true);
        },
        deleteSubject (item){
           this.deletedIndex = this.subject_enrolled.indexOf(item)
           this.deletedSubject = item
           this.dialogDelete = true
        },
        async deleteItemConfirm () {
            try {
               let data = []
                const result = await studentService.RemoveSubjectEnrolled({
                    offer_no: this.deletedSubject.offer_no,
                    student_no: this.student.stud_no,
                    term: this.termcd,
                    subj_id: this.deletedSubject.subj_id,
                    program_id: this.program,
                    totalCredit: this.sumCredit - this.deletedSubject.units,
                    yearLevel: this.yearlvl

                })
                if (result.status === 200) {
                 
                    const res = await studentService.GetSubjectEnrolled({
                        student_no: this.student.stud_no,
                        term: this.termcd
                    })
                    // console.log(res)
                    if( res.status === 200 ) {
                        //console.log(res)
                        this.subject_enrolled = []
                        this.sumCredit = 0
                        res.data.result.forEach((value, index) => {

                            let timessched = this.computeTime(value.time_tm, value.duration)
                            let daysched = this.computeDays(value.days)
                            let imultiplesched = _.findIndex(this.subject_enrolled, ['offer_no', value.offer_no])


                            if (imultiplesched != -1) {
                                this.subject_enrolled[imultiplesched].schedule = this.subject_enrolled[imultiplesched].schedule + ` <br> ` + daysched + '  ( ' + timessched + ' )'
                            } else {
                                data = {
                                    row: index + 1,
                                    offer_no: value.offer_no,
                                    faculty_name: value.faculty_name,
                                    subj_no: value.subj_no,
                                    subj_title: value.subj_title,
                                    schedule: daysched + '  ( ' + timessched + ' )',
                                    units: value.subj_id === '001111' || value.subj_id === '001117' ? 3 : value.credit_units,
                                    credit_effect: value.credit_effect,
                                    days: value.days,
                                    time_tm: value.time_tm,
                                    duration: value.duration,
                                    subj_id: value.subj_id,
                                    lab_fee: value.lab_fee,
                                    room_no: value.room_no
                                }

                                this.subject_enrolled.push(data)
                                if (value.subj_id === '001111' || value.subj_id === '001117') {
                                    this.sumCredit += 3;
                                } else {
                                    this.sumCredit += value.credit_units;
                                }
                            }

                        })
                        if (this.subject_enrolled.length < 1) {
                            this.dialogDelete = false
                            this.total_tuition = this.formatExample(0.00)
                            this.total_misc = this.formatExample(0.00)
                            this.other_fees = this.formatExample(0.00)
                            this.energy_fee = this.formatExample(0.00)
                            this.laboratory_fee = this.formatExample(0.00)
                            this.total_fees = this.formatExample(0.00)
                            this.other_charges = this.formatExample(0.00)
                            this.total_credits = this.formatExample(0.00)
                            this.total_balance = this.formatExample(0.00)
                        } else {
                            for (let i = 0; i < this.subject_enrolled.length; i++) {

                                this.subject_enrolled[i].row = i + 1
                            }
                            // console.log(this.sumCredit)
                            this.dialogDelete = false
                            this.total_tuition = this.formatExample(result.data.fee.tuition)
                            this.total_misc = this.formatExample(result.data.fee.misc)
                            this.other_fees = this.formatExample(result.data.fee.other_fees)
                            this.energy_fee = this.formatExample(result.data.fee.energy)
                            this.laboratory_fee = this.formatExample(result.data.fee.laboratory)
                            this.total_fees = this.formatExample(result.data.fee.total_fees)
                            this.other_charges = this.formatExample(result.data.fee.other_charges)
                            this.total_credits = this.formatExample(result.data.fee.total_credits)
                            this.TotCredits = result.data.fee.total_credits
                            this.total_balance = this.formatExample(((result.data.fee.total_fees + result.data.fee.other_charges) - result.data.fee.total_credits) + this.BackAccountAmount)
                        }
                        }
                       
                    this.dialogDelete = false
                } else {
                    this.dialogDelete = false
                    this.subject_enrolled = []
                }
            } catch (error) {
                console.log(error)
            }
            
             
        },
        closeDelete() {
            this.deletedIndex = ''
            this.deletedSubject = []
            this.dialogDelete = false
        },
        async SetEnrolledAccount() {
          // await this.SetTotalCredit()
          await this.SetTerm(this.student.program_id)
          await this.GetEndTerm()
          await this.SetCurricular(this.student.stud_no, this.termcd)
          await this.changeProgram()
          await this.SetSubjectsEnrolled(this.student.stud_no, this.termcd)
          await this.SetDownPayment(this.student.stud_no, this.termcd)
          this.SetHeader(window.innerWidth)
          this.SetPrintOutData()
            
        },
        async SetDownPayment(student_no, termcd) {
            try {
                const result = await studentService.GetDownPayment({
                    student_no: student_no,
                    termcd: termcd
                })
                if (result.status === 200) {
                    this.DownPayment = result.data.result
                }
            } catch (error) {
                console.log(error)
            }
        },
        async SetSubjectsEnrolled(student_no,term) {
            try {
                let data = []
                const result = await studentService.GetSubjectEnrolled({
                    student_no: student_no,
                    term: term
                })
                
                if (result.status === 200) {
                    
                    this.subject_enrolled = []
                    this.sumCredit = 0
                    let ii = 0
                     result.data.result.forEach((value, index) => {
                        if (!this.BtnProgram) {
                            let timessched = this.computeTime(value.time_tm, value.duration)
                            let daysched = this.computeDays(value.days)
                            let imultiplesched = _.findIndex(this.subject_enrolled, ['offer_no', value.offer_no])



                            if (imultiplesched != -1) {
                                this.subject_enrolled[imultiplesched].schedule = this.subject_enrolled[imultiplesched].schedule + ` <br> ` + daysched + '  ( ' + timessched + ' )'
                            } else {
                                data = {
                                    row: ii + 1,
                                    offer_no: value.offer_no,
                                    faculty_name: value.faculty_name,
                                    subj_no: value.subj_no,
                                    subj_title: value.subj_title,
                                    schedule: daysched + '  ( ' + timessched + ' )',
                                    units: value.subj_id === '001111' || value.subj_id === '001117' ? 3 : value.credit_units,
                                    credit_effect: value.credit_effect,
                                    days: value.days,
                                    time_tm: value.time_tm,
                                    duration: value.duration,
                                    subj_id: value.subj_id,
                                    lab_fee: value.lab_fee,
                                    room_no: value.room_no
                                }

                                this.subject_enrolled.push(data)
                            }
                        } else {
                            data = {
                                row: index + 1,
                                offer_no: value.offer_no,
                                faculty_name: value.faculty_name,
                                subj_no: value.subj_no,
                                subj_title: value.subj_title,
                                units: value.credit_units,
                        
                            }

                            this.subject_enrolled.push(data)
                        }
                      
                    })
                    if (this.subject_enrolled.length < 1) {
                        this.total_tuition = this.formatExample(0.00)
                        this.total_misc = this.formatExample(0.00)
                        this.other_fees = this.formatExample(0.00)
                        this.energy_fee = this.formatExample(0.00)
                        this.laboratory_fee = this.formatExample(0.00)
                        this.total_fees = this.formatExample(0.00)
                        this.other_charges = this.formatExample(0.00)
                        this.total_credits = this.formatExample(0.00)
                        this.total_balance = this.formatExample(0.00)
                    } else {
                        for (let i = 0; i < this.subject_enrolled.length; i++) {
                            this.subject_enrolled[i].row = i + 1
                            this.sumCredit += this.subject_enrolled[i].units
                        }


                        const fees = await studentService.GetAllFees({
                            program: this.program,
                            year_level: this.yearlvl,
                            totalCredit: this.sumCredit,
                            student_no: this.student.stud_no
                        })
                        // console.log(fees)
                        if (fees.status === 200) {
                        
                            this.total_tuition = this.formatExample(fees.data.result.tuition)
                            this.total_misc = this.formatExample(fees.data.result.misc)
                            this.other_fees = this.formatExample(fees.data.result.other_fees)
                            this.energy_fee = this.formatExample(fees.data.result.energy)
                            this.laboratory_fee = this.formatExample(fees.data.result.laboratory)
                            this.total_fees = this.formatExample(fees.data.result.total_fees)
                            this.other_charges = this.formatExample(fees.data.result.other_charges)
                            this.total_credits = this.formatExample(fees.data.result.total_credits)
                            this.TotCredits = fees.data.result.total_credits
                            this.total_balance = this.formatExample(((fees.data.result.total_fees + fees.data.result.other_charges) - fees.data.result.total_credits) + this.BackAccountAmount)
                        }
                        return 1
                    }

                    
                } else {
                    this.subject_enrolled = []
                    return 1
                }
            } catch (error) {
                console.log(error)
            }
        },
        async SetCurricular(student_no,term) {
            try {
                const result = await studentService.GetProgramEnrolled({
                    student_no: student_no,
                    term: term
                })
                if (result.status === 200) {
            
                   if (result.data.result === '') {

                   } else {
                       this.program = result.data.result[0].program_id
                       this.dateEnrolled = result.data.result[0].dt_enroled
                       this.presentYear = result.data.result[0].yr_level
                       //this.yearlvl = this.GetYearLevel(result.data.result[0].yr_level)
                       this.studSection = result.data.result[0].sect_name
                   }
                   
                }

            } catch (error) {
                console.log(error)
            }
        },
        async SetTerm (val) {
           try {
               const response = await studentService.GetTerm({
                   term: val,
                   studentNo: this.student.stud_no 
               }) 
               if (response.status === 200) {
                   this.term = response.data.term
                   this.termcd = response.data.termcd
               }
           } catch (e) {
               console.log(e)
           }
        },
        async GetCurricularProgram () {
            try {
                 const response = await studentService.GetDeegres()
                 if (response.status === 200) {
                     this.programs = response.data.data
                 }
            } catch (e) {
                console.log(e)
            }
        },
        computeTime(time, num) {
            let nums = num * 5
            let timein = moment(time).format('hh:mm A')
            let timeout = moment(timein, 'hh:mm A').add(nums, 'minutes').format('hh:mm A')
            let ret = timein + ' - ' + timeout
            // console.log(moment('2022-5-25 12:59 PM').isBetween('2022-5-25 11:00 AM', ' 2022-5-25 1:00 PM', undefined, '()]'))
            return ret
            
        },
        CheckTimeConflict(ExistTime, ExistDuration , SelectedTime , SelectedDuration , counter ) {

            // console.log(ExistTime, ExistDuration, SelectedTime, SelectedDuration, counter)
            
            let numExist = ExistDuration * 5
            let numSelect = SelectedDuration * 5
            let ExistTimeIn = moment(ExistTime).format('hh:mm A')
            let ExistTimeOut = moment(ExistTimeIn, 'hh:mm A').add(numExist, 'minutes').format('hh:mm A')
            let SelectedTimeIn = moment(SelectedTime).format('hh:mm A')
            let SelectedTimeOut = moment(SelectedTimeIn, 'hh:mm A').add(numSelect, 'minutes').format('hh:mm A')
            if ( counter === 1) {
                return moment('1900-01-01 ' + SelectedTimeIn).isBetween('1900-01-01 ' + ExistTimeIn, '1900-01-01 ' + ExistTimeOut, undefined, '(]')
            } else {
                return moment('1900-01-01 ' + SelectedTimeOut).isBetween('1900-01-01 ' + ExistTimeIn, '1900-01-01 ' + ExistTimeOut, undefined, '(]')
            }
            
            // let timein = moment(time).format('hh:mm A')
            // let timeout = moment(timein, 'hh:mm A').add(nums, 'minutes').format('hh:mm A')
            // let sel = moment(selected, 'hh:mm A')
            // console.log(moment('selected').isBetween(timein, timeout, undefined, '()]'))
        },
        computeDays (days) {
            let dayssched = ''
            for (let i = 0; i < days.length; i++) {
                switch (days[i]) {
                    case '1': 
                        dayssched += 'Mo'
                        break;
                    case '2':
                        dayssched += 'Tu'
                        break;
                    case '3': 
                        dayssched += 'We'
                        break;
                    case '4': 
                        dayssched += 'Th'
                        break;
                    case '5': 
                        dayssched += 'Fr'
                        break;
                    case '6': 
                        dayssched += 'Sa'
                        break;
                    case '7': 
                        dayssched += 'Su'
                        break;
                    default:
                        dayssched = ''
                        break;
                }
            }
           
            if (dayssched.length > 2)  {
                dayssched = dayssched.replace(/.{2}/g, '$&')
                dayssched = dayssched.substring(0, dayssched.length - 0)
            } 

            return dayssched
        },
        async GetSubjects(){
             if (this.EndTerm) {
                 this.DialogMessage = 'Online Enrollment has ended please proceed to SSC-R Manila Office for other concerns'
                this.ConflictDialog = true
                return 
            }
            this.subject_offered = []
            let data = []
            if (_.isEmpty(this.program)) {
                alert("Please Select Curricular Program/Course")
                return 
            }

         

            if (this.yearlvl.length === 0 ) {
                alert("Please Select Year Level")
                return
            }
            try {
                
                this.SubjectDialog = true
                const response = await studentService.GetSubjects({
                    type: this.program,
                    year_level: this.yearlvl,
                    student_no: this.student.stud_no
                })
                if (response.status === 200) {
                    response.data.result.forEach((value, index) => {
                       
                       let timessched =  this.computeTime(value.time_tm, value.duration)
                       let daysched = this.computeDays(value.days)
                       let imultiplesched = _.findIndex(this.subject_offered, ['offer_no', value.offer_no])
                      

                       if (imultiplesched != -1) {
                           this.subject_offered[imultiplesched].schedule = this.subject_offered[imultiplesched].schedule + ` <br> ` + daysched + '  ( ' + timessched + ' )'
                       }  else {
                            data = {
                                row: index + 1,
                                offer_no: value.offer_no,
                                faculty_name: value.faculty_name,
                                subj_no: value.subj_no,
                                subj_title: value.subj_title,
                                credit_units: value.subj_id === '001111' || value.subj_id === '001117' ? 3 : value.credit_units,
                                schedule: daysched + '  ( ' + timessched + ' )',
                                room_no: value.room_no,
                                subj_id: value.subj_id,
                                term_cd: value.term_cd,
                                days: value.days,
                                time_tm: value.time_tm,
                                duration: value.duration,
                                lab_fee: value.lab_fee,
                            }

                            this.subject_offered.push(data)
                       }
                    })
                    // this.subject_offered
                    
                }
            } catch(error) {
                console.log(error)
            }
        },
        async CheckConflictTime(selected) {
            let ret
            let offer_no = ''
            let value = []
            const SO = await studentService.GetOfferSchedule({offer_no: selected[0].offer_no, term_cd: this.termcd})
            const SE = await studentService.GetSubjectEnrolled({
                student_no: this.student.stud_no,
                term: this.termcd
            })
            if (SO.status === 200) {
               
                SO.data.result.forEach( (v,i) => {
                    let timein = moment(v.time_in, 'mm:hh A')
                    let duration = v.duration
                    let sdays = v.days
                    if (SE.status === 200) {
                        SE.data.result.forEach((v, i) => {
                            let sched = []
                            let days = v.days
                            for (let i = 0; i < days.length; i++) {
                                sched.push({
                                    day: days[i],
                                    time: v.time_tm,
                                    duration: v.duration
                                })

                            }
                                                    
                            for (let x = 0; x < sched.length; x++) {
                                offer_no = v.offer_no
                                if (ret) {
                                    value = {
                                        ret: ret,
                                        offer_no: v.offer_no
                                    }
                                    break
                                }
                                for (let y = 0; y < sdays.length; y++) {
                                    if (sdays[y] === sched[x].day) {

                                        if (this.CheckTimeConflict(sched[x].time, sched[x].duration, selected[0].time_tm, selected[0].duration, 1)) {
                                            ret = this.CheckTimeConflict(sched[x].time, sched[x].duration, selected[0].time_tm, selected[0].duration, 1)
                                            break

                                        } else if (this.CheckTimeConflict(sched[x].time, sched[x].duration, selected[0].time_tm, selected[0].duration, 0)) {
                                            ret = this.CheckTimeConflict(sched[x].time, sched[x].duration, selected[0].time_tm, selected[0].duration, 0)
                                            break
                                        } else {
                                            ret = false
                                        }
                                    }
                                }
                            }

                        })

                    }
                    
                })
            }
            
           
            if (ret) {
                value = {
                    ret: ret,
                    offer_no: offer_no

                }
            }
            
            return value
        },
        async SetSubjects () {
            if (!_.isEmpty(this.selected) ) {
                // this.selected.forEach((value, index) => {
                //     console.log(value.offer_no)
                // })
                if (!_.isEmpty(this.subject_enrolled)) {
                    let checkconflict = await this.CheckConflictTime(this.selected)
                    if (checkconflict.ret) {
                        if (this.cnflag === 'Y') {
                            this.DialogMessage = 'Please select another subject. schedule is conflict'
                            this.ConflictDialog = true
                            return 
                        } else {
                            this.DialogMessage = 'This subject is in conflict with Offer No.: ' + checkconflict.offer_no
                            this.ConflictDialog = true
                        }
                    }
                }
              
                if (this.selected[0].credit_units + this.sumCredit > this.totalCredit) {
                    this.DialogMessage = 'Units Overload. The maximum unit allowed is ' +  this.totalCredit + ' units'
                    this.ConflictDialog = true
                    return 
                }
                let data = []
                
                const result = await studentService.SaveSubjectsEnrolled({
                    program: this.program,
                    year_level: this.yearlvl,
                    student_no: this.student.stud_no,
                    subjects: this.selected,
                    totalCredit: this.selected[0].credit_units + this.sumCredit
                })
                if (result.status  === 200 ) {
                    
                    this.subject_enrolled = [] 
                    this.selected = []
                    
                    result.data.result.forEach((value, index) => {

                       let timessched =  this.computeTime(value.time_tm, value.duration)
                       let daysched = this.computeDays(value.days)
                       let imultiplesched = _.findIndex(this.subject_enrolled, ['offer_no', value.offer_no])
                      
                        
                       if (imultiplesched != -1) {
                            this.subject_enrolled[imultiplesched].schedule = this.subject_enrolled[imultiplesched].schedule + ` <br> ` + daysched + '  ( ' + timessched + ' )'
                       }  else {
                            data = {
                                row: index + 1,
                                offer_no: value.offer_no,
                                faculty_name: value.faculty_name,
                                subj_no: value.subj_no,
                                subj_title: value.subj_title,
                                schedule: daysched + '  ( ' + timessched + ' )',
                                units: value.subj_id === '001111' || value.subj_id === '001117' ? 3 : value.credit_units,
                                credit_effect: value.credit_effect,
                                days: value.days,
                                time_tm: value.time_tm,
                                duration: value.duration,
                                subj_id: value.subj_id,
                                room_no: value.room_no,
                                lab_fee: value.lab_fee
                            }
                        
                            this.subject_enrolled.push(data)
                          
                        
                       }
                    })
                    this.sumCredit = 0
                    if (this.subject_enrolled.length < 1) {
                        this.total_tuition = this.formatExample(0.00)
                        this.total_misc = this.formatExample(0.00)
                        this.other_fees = this.formatExample(0.00)
                        this.energy_fee = this.formatExample(0.00)
                        this.laboratory_fee = this.formatExample(0.00)
                        this.total_fees = this.formatExample(0.00)
                        this.other_charges = this.formatExample(0.00)
                        this.total_credits = this.formatExample(0.00)
                        this.total_balance = this.formatExample(0.00)
                        this.SubjectDialog = false
                    } else {
                        
                        this.total_tuition = this.formatExample(result.data.fee.tuition)
                        this.total_misc = this.formatExample(result.data.fee.misc)
                        this.other_fees = this.formatExample(result.data.fee.other_fees)
                        this.energy_fee = this.formatExample(result.data.fee.energy)
                        this.laboratory_fee = this.formatExample(result.data.fee.laboratory)
                        this.total_fees = this.formatExample(result.data.fee.total_fees)
                        this.other_charges = this.formatExample(result.data.fee.other_charges)
                        this.total_credits = this.formatExample(result.data.fee.total_credits)
                        this.TotCredits = result.data.fee.total_credits
                        this.total_balance = this.formatExample(((result.data.fee.total_fees + result.data.fee.other_charges) - result.data.fee.total_credits) + this.BackAccountAmount)
                        this.SubjectDialog = false
                    }
                    for (let i = 0; i < this.subject_enrolled.length; i++) {
                       
                        this.subject_enrolled[i].row = i + 1
                        this.sumCredit += this.subject_enrolled[i].units
                    }
                    

                } else {
                     alert('Somthing wrong please try again later!')
                } 

            } else {
                this.DialogMessage = 'Please select any subject to enroll'
                this.ConflictDialog = true
            }
        },
        closeDialog () {
            this.ConflictDialog = false
        },
        payment () {
            if (this.EndTerm) {
                 this.DialogMessage = 'Online Enrollment has ended please proceed to SSC-R Manila Office for other concerns'
                this.ConflictDialog = true
                return 
            }
            this.PaymentDialog = true
            this.AmountPayment = this.formatExample(this.DownPayment)
        }  
    }
}
</script>
<style scoped>






* {
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

table tr td {
    font-size: 12px;
}

.fl {
    float: left
}

.fr {
    float: right;
    font-size: 10px;

}

.title {
    font-family: "Old English Text MT";
    text-align: center;
    font-size: 50px !important;
    margin-bottom: 5px;
    font-weight: 600;
}

.sub-title {
    text-align: center;
    padding: 0;
    margin: 0;

    font-size: 14px;
}

.study-load-title {
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
}

.study-load-sub-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.promisory-title {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
}

.column {
    float: left;
    padding: 3px;
}

.left,
.right {
    width: 20%;
}

.middle {
    width: 60%;
}

.row::after {
    content: "";
    display: table;
    clear: both;
}

.bold {
    font-weight: 800;
    font-size: 12px;
}

.cols1 {
    width: 10%;
}

.cols2 {
    width: 20%;
}

.cols2-1 {
    width: 25%;
}

.cols3 {
    width: 30%
}

.cols4 {
    width: 40%;
}

.cols4-1 {
    width: 35%;
}

.cols5 {
    width: 50%;
}

.cols6 {
    width: 60%;
}

.cols7 {
    width: 70%;
}

.cols10 {
    width: 100%;
}

.ml-1 {
    margin-left: 10px;
}

.mt-1 {
    margin-top: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.mr-2 {
    margin-right: 20px;
}

.mt-2 {
    margin-top: 20px;
}

.mb-2 {
    margin-bottom: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.mr-3 {
    margin-right: 30px;
}

.mt-3 {
    margin-top: 30px;
}

.mb-3 {
    margin-bottom: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.mr-4 {
    margin-right: 40px;
}

.mt-4 {
    margin-top: 40px;
}

.mb-4 {
    margin-bottom: 40px;
}

.ml-5 {
    margin-left: 50px;
}

.mr-5 {
    margin-right: 50px;
}

.mt-5 {
    margin-top: 50px;
}

.mb-5 {
    margin-bottom: 50px;
}

.text-right {
    text-align: right;
    font-size: 12px;
}

.text-center {
    margin: 5px 0;
    text-align: center;
    font-size: 12px;
}

.notes {
    font-size: 10px;
}

table {
    width: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

.indent-text {
    text-indent: 30px;
}

.text-10 {
    font-size: 11px;
}

.no-border,
td {
    border: none;
}

.textbold {
    font-weight: bold;
}
.ddnone {
    display: none;
}
.v-card__actions>.v-btn.v-btn {
    padding: 0 8px !important;
}
.notify-bg {
    background-color: rgba(59,161,255, 0.2);
}
.logo-study-load {
    position: relative;
    top: 60px;
    left: -15px;
}
.height-10{
    height: 10%;
}
.cols1-2 {
    width: 7.5%;
}
.cols1-3 {
    width: 15%;
}
</style>