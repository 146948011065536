<template>
   <v-container class="fill-height fill-width" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                    <v-img
                      src="/SSCR.png"
                      alt="San Sebastian College-Recoletos"
                      contain
                      height="150"
                      class="mt-5 mb-2"
                    ></v-img>
                    <v-card-text class="mb-15">
                    <v-divider class="linedivider my-5"></v-divider>
                    <div class="text-center" v-if="err">
                        <h1>{{ message }}</h1>
                        <v-btn 
                          class="font-weight-bold"
                          color="orange darken-2"
                          dark
                          x-large
                          @click="Login"
                        >Login</v-btn>
                    </div>
                    <div class="text-center" v-if="!err">
                        <h2 class="mb-5 mt-5">{{ message }}</h2>
                        <v-form lazy-validation ref="passwordForm" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                   <v-text-field
                                        v-model='password'
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                                        :type="show ? 'text' : 'password'"
                                        name='input-10-1'
                                        label='Password'
                                        hint='At least 8 characters'
                                        counter 
                                        @click:append='show = !show'
                                        class="font-weight-bold"
                                        outlined
                                        :rules="[rules.required,rules.min]"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model='confirmpassword'
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                                        :type="show1 ? 'text' : 'password'"
                                        name='input-10-1'
                                        label='Confirm-Password'
                                        hint='At least 8 characters'
                                        counter 
                                        @click:append='show1 = !show1'
                                        class="font-weight-bold"
                                        outlined
                                        :rules="[rules.required, passwordMatch]"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <v-divider class="linedivider my-5"></v-divider>
                    </v-card-text>
                    <v-card-actions v-if="!err">
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="valid"
                            :loading="dialog"
                            class="ma-2 px-11"
                            color="orange darken-2"
                            dark
                            elevation="4"
                            x-large
                            @click="UpdatePassword"
                        >
                            Submit
                        </v-btn>
                        <v-btn
                            v-else
                            class="ma-2 px-11"
                            color="blue-grey lighten-2"
                            dark
                            elevation="4"
                            x-large
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import studentService from '@/services/student'
export default {
    name: 'VerifyAccount',
    data: () => ({
        err: false,
        dialog: false,
        message: '',
        show: false,
        show1: false,
        password:'',
        confirmpassword: '',
        valid: true,
        studno: '',
        email: '',
        rules: {
            required: value => !!value || 'Required.',
            min: v => (v && v.length >= 8) || 'Minimum of 7 characters',
            max: v => (v && v.length <= 50) || 'Maximum of 50 characters',
            maxphone: v => (v && v.length <=13) || 'Maximum of 13 numbers',
            minphone: v => (v && v.length >=7) || 'Must not lesser than 7 numbers',
            minMember: v => (v && v.length >= 6) || 'Min 6 characters',
            email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            maximagesize: v => !v || v.size < 2000000 || 'Upload size document should be less than 2 MB!',
            age: v => v > 2 || 'Please Enter Correct Birth Date' 
        },

    }),
    computed: {
        passwordMatch () {
            if (this.password === this.confirmpassword) {
                return true
            } else {
                return 'Password did not match'
            }
        }
    },
    created: async function () {
        try {
        const response = await studentService.VerifyNewStudentAccount({
            token: this.$route.params.token

        })
        console.log(response.data.result)
        this.err = false
        this.studno = response.data.result.stud_no
        this.email = response.data.result.email_add
        this.message = response.data.message
        } catch (e) {
            this.err = true
            this.message = e.response.data.error
        }
    },
    methods: {
        Login () {
            this.$router.push('/')
        },
        async UpdatePassword () {
            try {
                if (this.$refs.passwordForm.validate()) {
                    this.dialog = true
                    const response = await studentService.CreateNewStudentPassword({
                        password: this.password,
                        studno: this.studno,
                        email: this.email
                    })
                    this.err = false
                    this.message = response.data.message
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 10000)
                }            
            } catch (e) {
                this.err = true
                this.message = e.response.data.error
            }  
        }
    }
}
</script>
<style scoped>

</style>