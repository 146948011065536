<template>
    <div>
        <v-card class="mx-auto cardbg" outlined>
            <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2"></v-img>
            <v-card-title elevation="10"
                class="font-weight-black text-center text-h4 justify-center white--text cdtitle">
                SAN SEBASTIAN COLLEGE-RECOLETOS
            </v-card-title>
            <v-card-text>
                <v-tabs fixed-tabs color="red" background-color="amber lighten-1"
                    next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" dark
                    show-arrows v-model="tabs">
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab class="font-weight-black colorTabs" href="#studentprofile">
                        Student Profile
                    </v-tab>
                    <v-tab class="font-weight-black colorTabs">
                        Student Logs
                    </v-tab>
                    <v-tab class="font-weight-black colorTabs" @click="refresh=true">
                        Student Account
                    </v-tab>
                    <v-tab class="font-weight-black colorTabs" @click="checkBackAccount">
                        Enrollment
                    </v-tab>
                    <v-tab class="font-weight-black colorTabs">
                        Grades
                    </v-tab>
                    <!-- <v-tab class="font-weight-black colorTabs">
                        Document Upload
                    </v-tab> -->
                    <v-tab @click="logout" class="font-weight-black colorTabs" href="#studentprofile">
                        Logout
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs" align-with-title>
                    <v-tab-item value="studentprofile">
                        <template>
                            <StudentProfile />
                        </template>
                    </v-tab-item>
                    <v-tab-item>
                        <template>
                            <StudentLogs :student="studentinfo" :refresh="refresh" />
                        </template>
                    </v-tab-item>
                    <v-tab-item>
                        <template>
                            <StudentAccounts :student="studentinfo" :refresh="refresh" />
                        </template>
                    </v-tab-item>
                    <v-tab-item :disabled="WithBackAccount">
                        <template>
                            <Enrollment :student="studentinfo" :cnflag="cnflag" />
                        </template>
                    </v-tab-item>
                    <v-tab-item>
                        <template>
                            <Grades :student="studentinfo" />
                        </template>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <template>
                            <UploadDocument :student="studentinfo" />
                        </template>
                    </v-tab-item> -->
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <v-dialog v-model="WithBackAccount" max-width="500px">
            <v-card>
                <v-card-title class="text-h6">Please Settle first your remaining balance in the amount of {{ BackAmount
                }} to
                    continue enrollment
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="CloseDialog">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import studentService from '@/services/student'
import StudentProfile from '../components/HomeComponents/StudentProfile.vue'
import UploadDocument from '../components/HomeComponents/UploadDocuments.vue'
import Enrollment from '../components/HomeComponents/Enrollment.vue'
import StudentAccounts from '../components/HomeComponents/StudentAccounts.vue'
import numeral from 'numeral'
import Grades from '../components/HomeComponents/Grades.vue'
import StudentLogs from '../components/HomeComponents/StudentLogs.vue'

export default {
    name: 'Home',
    data: () => ({
        studentinfo: '',
        cnflag: '',
        tabs: null,
        tabval: '',
        term: '',
        termcd: '',
        refresh: true,
        WithBackAccount: false,
        BackAmount: 0
    }),
  mounted () {
    this.getStudent()

  },
  methods: {
      formatExample(value) {
          // Just an simple example, you can do anything here
          return numeral(value).format('0,0.00')
      },
        async getStudent () {
            try {
            const response = await studentService.GetLoggedStudent()
            this.studentinfo = response.data.result
            this.cnflag = response.data.cnflag
            this.$store.dispatch('setStudent', this.studentinfo  )
           
            } catch (e) {
               this.$router.push('/')
            }
        },
        async logout () {
            if (confirm('Are you sure you want to Log out?')) {
                const response = await studentService.LogoutStudent()
                if (response.status = 200) {
                    alert('Successfully Logout')
                    this.$router.push('/')
                }
            } 
        },
      async checkBackAccount () {
          await this.SetTerm(this.studentinfo.program_id)
          const result = await studentService.GetBackAccount({
              student_no: this.studentinfo.stud_no,
              term: this.termcd
          })

          if (result.status === 200) {
              if (result.data.result[0].back_account > 0) {
                  this.BackAmount = this.formatExample(result.data.result[0].back_account)
                  this.WithBackAccount = true
              }
          }
      },
      async SetTerm(val) {
          try {
              const response = await studentService.GetTerm({
                  term: val,
                  studentNo: this.studentinfo.stud_no
              })
              if (response.status === 200) {
                  this.term = response.data.term
                  this.termcd = response.data.termcd
              }
          } catch (e) {
              console.log(e)
          }
      },
      CloseDialog () {
          this.WithBackAccount = false
          this.tabs = 0
      },
        
    },
     components: {
        StudentProfile,
        UploadDocument,
        Enrollment,
        StudentAccounts,
        Grades,
        StudentLogs
    }
}
</script>

<style>
.cardbg {
     background-color: rgba(255,204,0,.2) !important; 
}
.colorTabs {
    color: rgba(255,255,255,1) !important;
}
.theme--dark.v-tabs .v-tab--active:hover::before, .theme--dark.v-tabs .v-tab--active::before {
    color: #D32F2F !important;
    opacity: 0.24;
}
.cdtitle {
    text-shadow: 4px 2px 6px black;    
}
.colorTabs.v-tab--active  {
    color: #D32F2F !important; 
}


</style>