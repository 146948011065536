<template>
    <div>
        <v-container class="mt-5">
            <v-row >
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                     <v-text-field
                        :value = students.stud_no
                        outlined
                        hint="Student No."
                        persistent-hint
                        dense
                        v-model="student.stud_no"    
                        readonly
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange darken-2"
                    class="font-weight-bold ma-3"
                    dark
                    large
                    v-show = "!edit"
                    @click="EditStudentProfile"
                >
                    EDIT STUDENT PROFILE
                </v-btn>
                <v-btn
                     color="orange darken-2"
                    class="font-weight-bold ma-3"
                    dark
                    large
                    v-show = "edit"
                    @click="UpdateStudentProfile"
                > UPDATE </v-btn>
                <v-btn
                     color="orange darken-2"
                    class="font-weight-bold ma-3"
                    dark
                    large
                    v-show="edit"
                    @click="EditStudentProfile"
                > CANCEL </v-btn>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="Last Name"
                        persistent-hint
                        :value = students.lastname
                        outlined
                        dense
                        readonly
                        v-model="student.lastname"
                        >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="First Name"
                        persistent-hint
                        :value = students.firstname
                        outlined
                        dense
                        readonly
                        v-model="student.firstname"
                        >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="Middle Name"
                        persistent-hint
                        :value = students.midname
                        outlined
                        dense
                        readonly
                        v-model="student.midname"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="Gender"
                        persistent-hint
                        :value= "students.gender == 'M' ? 'Male' : 'Female'"
                        outlined
                        dense
                        readonly
                        v-model="student.gender"
                        >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="Birth Date"
                        persistent-hint
                        :value= students.birthdate
                        outlined
                        dense
                        readonly
                        v-model="student.birthdate"
                        >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-text-field
                        hint="Birth Place"
                        persistent-hint
                        :value= students.birth_place
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.birth_place"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-select 
                        dense
                        hint="Civil Status"
                        persistent-hint
                        v-model="student.civ_stat"
                        item-text="text"
                        item-value="value" 
                        :items="civil_status" 
                        outlined class="font-weight-bold"
                         :readonly = "readonly_fields"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-select
                        dense
                        hint="Nationality"
                        persistent-hint
                        v-model="student.nat_cd"
                        item-text="nationality"
                        item-value="nat_cd"
                        :items="nationalities"
                        outlined
                         :readonly = "readonly_fields"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                    <v-select
                        dense
                        hint="Religion"
                        persistent-hint
                        v-model="student.relgn_no"
                        item-text="religion"
                        item-value="relgn_no"
                        :items="religions"
                        outlined
                         :readonly = "readonly_fields"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-text-field 
                        hint="Home Tel No."
                        persistent-hint
                        :value = students.home_tel_no
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.home_tel_no"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-text-field 
                        hint="Mobile No."
                        persistent-hint
                        :value = students.mobile_no
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.mobile_no"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
                    <v-text-field 
                        hint="Address"
                        persistent-hint
                        :value = students.home_addr_street
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.home_addr_street"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="3" lg="3" xl="3">
                    <v-text-field 
                        hint="City"
                        persistent-hint
                        :value = students.city_addr
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.city_addr"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="3" lg="3" xl="3">
                    <v-text-field 
                        hint="Zip Code"
                        persistent-hint
                        :value = students.city_zip
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.city_zip"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                 <v-col cols="12">
                    <v-subheader>
                        <h2>Parents/Guardian</h2>
                    </v-subheader>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-text-field 
                        hint="Father Name"
                        persistent-hint
                        :value = students.father
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.father"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-text-field 
                        hint="Mother Name"
                        persistent-hint
                        :value = students.mother
                        outlined
                        dense
                         :readonly = "readonly_fields"
                        v-model="student.mother"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
                     <v-text-field 
                        hint="Guardian name"
                        persistent-hint
                        :value = students.guardian
                        outlined
                        dense
                        :readonly = "readonly_fields"
                        v-model="student.guardian"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="orange darken-2" dark>
                <v-card-text class="text-center">
                    <div class="py-5">
                        <h3> {{ update_message }}</h3>
                    </div>
                <v-progress-circular :width="8" :size="70" color="white" indeterminate class="mx-10"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import studentService from '@/services/student'

export default {
    name: 'StudentProfile',
    data: () => ({
        student: [],
        dialog: false,
        edit: false,
        update_message: '',
        edit_caption: 'EDIT STUDENT PROFILE',
        readonly_fields: true,
        firstname: '',
        lastname: '',
        midname: '',
        gender: '',
        civ_stat: '',
        nat_cd: '',
        relgn_no: '',
        nationalities: [],
        religions: [],
        civil_status: [
            {'value' : 'S', 'text' : 'Single'},
            {'value' : 'M', 'text' : 'Married'},
            {'value' : 'D', 'text' : 'Divorced'},
            {'value' : 'W', 'text' : 'Widowed'}
        ],
    }), 
    computed: {
        students() {
            this.civ_stat = this.$store.state.student.civ_stat
            this.nat_cd = this.$store.state.student.nat_cd
            this.relgn_no = this.$store.state.student.relgn_no
            this.student = this.$store.state.student
            return this.$store.state.student
        },
    },
    mounted () {
        this.GetNationalities()
        this.GetReligions()
        this.StudentProfile()
    },
    methods: {
        StudentProfile() {
            this.student = this.$store.state.student
        },
        async GetNationalities () {
            try {
                const response = await studentService.GetNationalities()
                if (response.status === 200) {
                    this.nationalities =  response.data.result
                }
            } catch (error) {
                console.log('Error Message: ', error)
            }
        },
        async GetReligions() {
            try {
                const response = await studentService.GetReligions()
                if (response.status === 200) {
                this.religions = response.data.result
                }
            } catch (error) {
                console.log('Error Message: ', error)
            }
        },
        EditStudentProfile () {
            if (!this.edit) {
                this.edit = true
                this.readonly_fields = false
            } else {
                 this.edit = false
                 this.readonly_fields = true
            }
        },
        async UpdateStudentProfile() {
            try { 
                const response = await studentService.UpdateStudentProfile({student: this.student})
                if (response.status === 200) {
                    this.dialog = true
                    this.update_message = 'Updating Profile'
                    setTimeout(() => {
                        this.update_message = 'Done Updating Profile'
                    }, 5000)
                    setTimeout(() => {
                        this.dialog = false
                    }, 7000)
                } else {
                    console.log(response.data)
                }
                

            } catch (error) {
                console.log(error)
            }

            this.edit = false
            this.readonly_fields = true
            this.edit_caption = 'EDIT STUDENT PROFILE'
        }
    }
}
</script>
<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
</style>