import api from '@/services/Api'
import { get } from 'lodash'

export default {
    GetDeegres () {
        return api().post('/user/degrees')
    },
    GetNationalities () {
        return api().post('/user/nationality')
    },
    GetReligions () {
        return api().post('/user/religion')
    },
    CheckEmailExist (credentials) {
        return api().post('/user/emailexist', credentials)
    },
    RegisterNewStudents (credentials) {
        return api().post('/user/registerApplicant', credentials)
    },
    GetStudentNo (credentials) {
        return api().post('/user/checkstudentno', credentials)
    },
    GetStudentDetails (credentials) {
        return api().post('/user/studentDetail', credentials)
    },
    RegisterContinuingStudent (credentials) {
        return api().post('/user/registerStudent', credentials)
    },
    Login (credential) {
        return api().post('/user/login', credential)
    },
    GetLoggedStudent (credential) {
        return api().get('/user/student')
    },
    LogoutStudent () {
        return api().post('/user/logout')
    },
    VerifyStudentEmail (credential) {
        return api().post('/user/verifyemail', credential)
    },
    VerifyNewStudentAccount (credential) {
        return api().post('/user/confirm/account', credential)
    },
    CreateNewStudentPassword (credential) {
        return api().post('/user/update/studentaccount', credential)
    },
    ReUploadeNewRequirements (credential) {
        return api().post('/user/reupload/requirements' , credential)
    },
    ForgotStudentPassword (credentials) {
        return api().post('/user/forgotpassword', credentials)
    },
    CheckPasswordResetToken (credentials) {
        return api().post('/user/check/password/reset/token', credentials)
    },
    ChangePassword (credentials) {
        return api().post('/user/update/password', credentials)
    },
    CheckDocReference (credentials) {
        return api().post('/user/checkcollegecode', credentials)
    },
    GetTerm (credentials) { 
        return api().post('/user/getterm', credentials)
    },
    GetSubjects (credentials) {
        return api().post('/user/getSubjectOfferings', credentials)
    },
    SaveSubjectsEnrolled (credentials) {
        return api().post('/user/setsubjectsenrolled' , credentials)
    },
    GetProgramEnrolled (credentials) {
        return api().post('/user/getProgramEnrolled', credentials)
    },
    GetSubjectEnrolled (credentials) {
        return api().post('/user/getSubjectsEnrolled', credentials)
    },
    RemoveSubjectEnrolled (credentials) {
        return api().post('/user/removeSubjectEnrolled' , credentials)
    },
    GetOfferSchedule (credentials) {
        return api().post('/user/GetSelectedOfferSchedule', credentials)
    },
    GetBackAccount (credentials) {
        return api().post('/user/GetBackAccount', credentials)
    },
    GetAllFees (credentials) {
        return api().post('/user/getallfees', credentials)
    },
    GetPrivacyPolicy () {
        return api().post('/user/getprivacypolicy')
    },
    GetDownPayment (credentials) {
        return api().post('/user/getdownpayment', credentials)
    },
    GetCollegeID (credentials) {
        return api().post('/user/getCollegeID', credentials)
    },
    GetBlockCodes (credentials) {
        return api().post('/user/GetBlockCodes', credentials)
    },
    GetSubjectsOnBlockCode (credentials) {
        return api().post('/user/GetSubjectOnBlockCode', credentials)
    },
    GetDragonPayUrl (credentials) {
        return api().post('/user/GetDragonPayUrl' , credentials)
    },
    CheckDpayDigest (credentials) {
        return api().get('/user/CheckDpayDigest', credentials)
    },
    GetTotalCredits (credentials) {
        return api().post('/user/GetTotalCredits', credentials)
    },
    GetAccounts (credentials) {
        return api().get('/user/GetAccounts/' + credentials)
    },
    GetStudentAccountsDetails (credentials) {
        return api().get('/user/GetAccountsDetails/' + credentials.student_no + '/' + credentials.term)
    },
    GetValidDays () {
        return api().get('/user/getValidDays')
    },
    GetEndTerm (credential) {
        return api().get('/user/getendterm/' + credential)
    },
    UpdateStudentProfile (credentials) {
        return api().post('/user/UpdateStudentProfile', credentials)
    },
    getHeaderType (credential) {
        return api().get('/user/GetHeaderType/' + credential)
    },
    GetStudentGrades (credentials) {
        return api().get('/user/GetStudentGrades/' + credentials.student_no + '/' + credentials.term)
    },
    GetStudentLogs (credentials) {
        return api().get('/user/GetStudentLogs/' + credentials.studentNo + '/' + credentials.dateFrom + '/' + credentials.dateTo) 
    },
    ReSendVerification (credential) {
        return api().post('/user/reSendVerification/' + credential)
    },
    GetCurrentTerm () {
        return api().get('/user/GetCurrentTerm')
    },
    GetSelectedTerm (credentials) {
        return api().get('/user/SetSelectedTerm/'+ credentials.termYear + '/' + credentials.termCode)
    },
    GetRegistrar () {
        return api().get('/user/GetRegistrar');
    },
    GetTotalBalance (studentNo) {
        return api().get(`/user/student/account/balance/${studentNo}`);
    }
  
}