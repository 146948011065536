<template>
  <v-container class="fill-height fill-width" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-window v-model="step" touchless>
          <v-window-item :value="1">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-text>
                    <v-divider class="linedivider my-5"></v-divider>
                    <v-form ref="LoginForm" v-model="valid" lazy-validation>
                      <form @keyup.enter="Login">
                        <v-alert v-if="error" type="warning">
                          <span>
                            {{ message }}
                            <v-btn v-show="isVerified" text color="link" @click="reSendVerification">
                              Re-Send Verification!
                            </v-btn>
                          </span>
                        </v-alert>
                        <v-text-field label="Student No./Applicant No." v-model="studentno"
                          prepend-inner-icon="mdi-account" type="text" class="font-weight-bold" outlined
                          :rules="[rules.required]"></v-text-field>
                        <v-text-field label="Enter your password" v-model="password" prepend-inner-icon="mdi-lock"
                          type="password" class="font-weight-bold" outlined :rules="[rules.required]"></v-text-field>
                        <v-btn class="font-weight-bold" color="orange darken-2" dark x-large block @click="Login">Login
                        </v-btn>
                        <v-divider class="linedivider mt-5 mb-3"></v-divider>
                        <v-card-actions class="white--text">
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-checkbox color="#000000" class="font-weight-bold" label="Remember me"></v-checkbox>
                            </v-col>
                            <v-col cols="12" class="mb-3">
                              <v-btn text color="primary" class="forgetpass" @click="step=8">
                                Forgot your password?
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-spacer></v-spacer>
                          <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="step=2">
                            Register
                          </v-btn>
                        </v-card-actions>
                      </form>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="2">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-title class="mb-5">Please select type of Registration</v-card-title>
                  <v-divider class="linedivider"></v-divider>
                  <v-card-text class="mt-10 mb-10">
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="12" md="6" xl="6" lg="6" align="center">
                        <v-btn color="orange darken-2" dark tile x-large height=100 width=250 class="mb-2"
                          @click="clearinputs(3,'ns')">
                          New Student
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" xl="6" lg="6" align="center">
                        <v-btn color="orange darken-2" dark tile x-large height="100" width=250 class="mb-2"
                          @click="clearinputs(4,'cs')">
                          Continuing Student
                        </v-btn>
                      </v-col>
                      <v-col cols="12" align="center">
                        <v-btn color="orange darken-2" dark tile x-large height="100" width=250 class="mb-2"
                          @click="step = 7">
                          Re-Upload Requirements
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class="linedivider"></v-divider>
                  <v-card-actions>
                    <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="step=1">
                      <v-icon left>
                        mdi-arrow-left
                      </v-icon>
                      Back
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="3">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-title>
                    New Student Registration
                  </v-card-title>
                  <v-divider class="linedivider"></v-divider>
                  <v-card-text>
                    <v-form ref="NewStudentForm" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field label="Enter Email Address" v-model="email" prepend-inner-icon="mdi-email"
                            class="font-weight-bold" outlined :rules="[rules.required, rules.email, checkEmail]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4">
                          <v-combobox label="Select Classification" v-model="classtype" item-value="value"
                            item-text="text" :items="classification" outlined class="font-weight-bold"
                            @input="getclasstypevalue" :rules="[rules.required]"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" lg="8">
                          <v-text-field v-show="classtypevalue === 'T' || classtypevalue === 'C'" outlined
                            v-model="lastschool" label="Previous School" class="font-weight-bold uppcase"
                            :rules="[rules.required]"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4" md="4" lg="4">
                          <v-text-field outlined v-model="lrn" label="LRN No." class="font-weight-bold uppcase">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="8" md="8" lg="8">
                          <v-combobox v-model="degree" item-text="program" item-value="program_id" :items="degrees"
                            label="Degree Program" clearable outlined @input="SetDocumentsRequired"
                            class="font-weight-bold" :rules="[rules.required]"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4">
                          <v-text-field label="Year level" v-model="yearlevel" type="text" class="font-weight-bold"
                            outlined :rules="[rules.required]"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Last Name" v-model="lastname" type="text"
                            class="font-weight-bold uppcase" outlined autocapitalize :rules="[rules.required]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="First Name" v-model="firstname" type="text"
                            class="font-weight-bold uppcase" outlined :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Middle Name" v-model="middlename" type="text"
                            class="font-weight-bold uppcase" outlined :rules="[rules.required]"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-combobox label="Select Gender" v-model="gender" item-text="text" item-value="value"
                            :items="genders" clearable outlined class="font-weight-bold" :rules="[rules.required]">
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-combobox label="Select Civil Status" v-model="civstatus" item-text="text"
                            item-value="value" :items="civil_status" clearable outlined class="font-weight-bold"
                            :rules="[rules.required]"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-row no-gutters>
                            <v-col cols="12" xs="8" sm="8" md="8" lg="9">
                              <v-text-field v-model="bdate" label="Birthday date" type="date" outlined
                                :rules="[rules.required]" @input="ComputeAge"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="4" sm="4" md="4" lg="3">
                              <v-text-field label="Age" v-model="age" readonly type="number" outlined
                                class="font-weight-bold"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-text-field label="Birth Place" v-model="birthplace" outlined
                            class="font-weight-bold uppcase" :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                          <v-text-field label="Address" v-model="address" type="text" outlined
                            class="font-weight-bold uppcase" :rules="[rules.required]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Municipality/City" v-model="city" type="text" outlined
                            class="font-weight-bold uppcase" :rules="[rules.required]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2">
                          <v-text-field label="Zip Code" v-model="zipcode" type="number" outlined
                            class="font-weight-bold uppcase" :rules="[rules.required]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                          <v-text-field label="Home Tel No." type="number" v-model="hometelno" outlined
                            class="font-weight-bold inputTelNo"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                          <v-text-field label="Mobile No." type="number" v-model="telno" outlined
                            class="font-weight-bold inputTelNo" :rules="[rules.maxphone, rules.minphone]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                          <v-combobox v-model="nationality" item-text="nationality" item-value="nat_cd"
                            :items="nationalities" label="Select Nationality" clearable outlined
                            class="font-weight-bold" :rules="[rules.required]"></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                          <v-combobox v-model="religion" item-text="religion" item-value="relgn_no" :items="religions"
                            label="Select Religion" clearable outlined class="font-weight-bold"></v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-subheader>
                            <h2>Parents/Guardian</h2>
                          </v-subheader>
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field v-model="father" label="Father Name" outlined class="font-weight-bold uppcase">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field v-model="mother" label="Mother Name" outlined class="font-weight-bold uppcase">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field v-model="guardian" label="Guardian Name" outlined
                            class="font-weight-bold uppcase"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                          <v-text-field v-model='password' :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'" name='input-10-1' label='Password'
                            hint='At least 8 characters' counter @click:append='show1 = !show1' class="font-weight-bold"
                            outlined :rules="[rules.required,rules.min]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                          <v-text-field v-model='confirmpassword' :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'" name='input-10-1' label='Confirm-Password'
                            hint='At least 8 characters' counter @click:append='show1 = !show1' class="font-weight-bold"
                            outlined :rules="[rules.required, passwordMatch]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <v-checkbox v-model="OriginalDocuments" label="Original documents to be submitted on site"
                            @click="OrigDocument" class="font-weight-black"></v-checkbox>
                        </v-col>
                        <template>
                          <v-col v-for="doc in docRequired" :key="doc.code" cols="12" sm="12" md="6" lg="6">
                            <v-file-input v-model="docSubmit[doc.code]" class="font-weight-bold" :id="doc.code" counter
                              :label="'Upload ' + doc.doc_name" placeholder="Please select a file"
                              prepend-icon="mdi-paperclip" outlined :show-size="1000"
                              :rules="[rules.required, rules.maximagesize, rules.AcceptedFiletype]"
                              accept="image/png, image/jpeg, image/bmp, application/pdf">
                            </v-file-input>
                          </v-col>
                        </template>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-checkbox v-model="DataPrivacy" class="font-weight-black">
                            <template v-slot:label>
                              <div @click.stop>
                                I agree to the
                                <a href="#" @click.prevent="PrivacyPolicy = true">
                                  Privacy Policy
                                </a>
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-divider class="my-3 linedivider" dark></v-divider>
                  <v-card-actions class="my-5">
                    <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="step=2">
                      <v-icon left>
                        mdi-arrow-left
                      </v-icon>
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="valid" :loading="dialog" class="ma-2 px-11" color="orange darken-2" dark elevation="4"
                      x-large @click="RegisterNewStudent">
                      Register
                    </v-btn>
                    <v-btn v-else class="ma-2 px-11" color="blue-grey lighten-2" dark elevation="4" x-large>
                      Register
                    </v-btn>
                    <v-alert v-if="st" dense type="info">
                      {{ submitStatus }}
                    </v-alert>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="4">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-title>
                    Continuing Student Registration
                  </v-card-title>
                  <v-divider class="linedivider my-5"></v-divider>
                  <v-card-text>
                    <v-form ref="ContinuingStudentForm" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Student No." class="font-weight-bold" v-model="studentno" outlined
                            :rules="[rules.required,CheckStudentNoRegistered]"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="8" lg="8">
                          <v-text-field label="E-mail" class="font-weight-bold" outlined v-model="email"
                            :rules="[rules.required, rules.email, GetStudentDetails]" :disabled="studFound"
                            ref="EmailRef"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Last Name" v-model="lastname" class="font-weight-bold uppcase" outlined
                            :rules="[rules.required, GetLastName]" :disabled="studFound"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="First Name" v-model="firstname" class="font-weight-bold uppcase" outlined
                            :rules="[rules.required, GetFirstName]" :disabled="studFound"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Middle Name" v-model="middlename" class="font-weight-bold uppcase"
                            outlined :disabled="studFound"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-combobox label="Select Gender" v-model="gender" item-text="text" item-value="value"
                            :items="genders" clearable outlined class="font-weight-bold" @input="GetGenderValue"
                            :rules="[rules.required, GetGenderStud]" :disabled="studFound"></v-combobox>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6" md="4" lg="4">
                          <v-text-field v-model="bdate" label="Birthday date" type="date" outlined
                            class="font-weight-bold" :rules="[rules.required, GetBirthDateStud]" :disabled="studFound"
                            @input="ComputeAge"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6" md="4" lg="4">
                          <v-text-field label="Age" v-model="age" readonly type="number" outlined
                            class="font-weight-bold"></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="6">
                          <v-text-field v-model='password' :rules="[rules.required, rules.min]"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                            name='input-10-1' label='Password' hint='At least 8 characters' counter
                                           class="font-weight-bold" outlined :disabled="studFound">
                          </v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="6">
                          <v-text-field v-model='confirmpassword' :rules="[rules.required, passwordMatch]"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                            name='input-10-1' label='Confirm-Password' hint='At least 8 characters' counter
                            @click:append='show1 = !show1' class="font-weight-bold" outlined :disabled="studFound">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="linedivider my-8"></v-divider>
                      <v-row>
                        <v-col class="py-0" cols="12" sm="12" md="12" lg="12">
                          <v-text-field label="Address" v-model="address" type="text" outlined
                            class="font-weight-bold text-capitalize" readonly>
                          </v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-text-field label="Tel No./Mobile No." v-model="telno" outlined
                            class="font-weight-bold inputTelNo" readonly></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-select v-model="nationality" item-text="nationality" item-value="nat_cd"
                            :items="nationalities" label="Select Nationality" clearable outlined
                            class="font-weight-bold" readonly></v-select>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="12" md="4" lg="4">
                          <v-select v-model="religion" item-text="religion" item-value="relgn_no" :items="religions"
                            label="Select Religion" clearable outlined class="font-weight-bold" readonly></v-select>
                        </v-col>
                        <v-col class="py-0" cols="12">
                          <v-select v-model="degree" item-text="program" item-value="program_id" :items="degrees"
                            label="Degree Program" clearable outlined class="font-weight-bold" readonly></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-divider class="my-3 linedivider"></v-divider>
                  <v-card-actions class="my-5">
                    <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="step=2">
                      <v-icon left>
                        mdi-arrow-left
                      </v-icon>
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="valid" class="ma-2 px-11" color="orange darken-2" dark elevation="4" x-large
                      @click="RegisterContinuingStudent">
                      Submit
                    </v-btn>
                    <v-btn v-else class="ma-2 px-11" color="blue-grey lighten-2" dark elevation="4" x-large>
                      Register
                    </v-btn>
                  </v-card-actions>
                  <v-alert v-if="st" dense type="info">
                    {{ submitStatus }}
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="5">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="6" lg="6">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="200" class="mt-5 mb-2">
                  </v-img>
                  <v-divider class="linedivider my-10"></v-divider>
                  <v-card-text class="text-center">
                    <h2 class="my-2 font-weight-black dark">{{ registerMessage }}</h2>
                  </v-card-text>
                  <v-card-actions>
                    <v-divider class="linedivider my-5 text-center"></v-divider>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="6">
            <v-card class="elevation-12 vcardbg" outlined>
              <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="300" width="450"
                class="mt-5 mb-2"></v-img>
              <v-card-text>
                <v-divider class="linedivider my-5 text-center"></v-divider>
                <h1>Error there is something wrong please contact SSCR office!</h1>
              </v-card-text>
            </v-card>
          </v-window-item>
          <v-window-item :value="7">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-title class="mb-5">Re-Upload Requirements</v-card-title>
                  <v-divider class="linedivider"></v-divider>
                  <v-card-text>
                    <v-alert type="error" v-if="error">
                      {{ message }}
                    </v-alert>
                    <v-form lazy-validation v-model="valid" ref="ReUploadForm">
                      <v-row>
                        <v-col col="12" xl="12" md="12" lg="12">
                          <v-text-field label="Enter Email Address" v-model="email" class="font-weight-bold"
                            :rules="[rules.required, rules.email]" outlined></v-text-field>
                        </v-col>
                        <v-col col="12" xl="12" md="12" lg="12">
                          <v-combobox v-model="degree" item-text="program" item-value="program_id" :items="degrees"
                            label="Degree Program" clearable outlined @input="SetDocumentsRequired"
                            class="font-weight-bold" :rules="[rules.required]"></v-combobox>
                        </v-col>
                        <v-col col="12" xl="12" md="12" lg="12">
                          <v-text-field label="Enter Password" v-model="password" class="font-weight-bold"
                            :rules="[rules.required]" outlined :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'" @click:append='show1 = !show1'></v-text-field>
                        </v-col>
                        <template>
                          <v-col v-for="doc in docRequired" :key="doc.code" cols="12" sm="12" md="6" lg="6">
                            <v-file-input v-model="docSubmit[doc.code]" class="font-weight-bold" :id="doc.code" counter
                              :label="'Upload ' + doc.doc_name" placeholder="Please select a file"
                              prepend-icon="mdi-paperclip" outlined :show-size="1000"
                              :rules="[rules.maximagesize, rules.AcceptedFiletype]"
                              accept="image/png, image/jpeg, image/bmp, application/pdf">
                            </v-file-input>
                          </v-col>
                        </template>
                      </v-row>
                    </v-form>
                    <v-divider class="linedivider"></v-divider>
                    <v-card-actions>
                      <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="step=2">
                        <v-icon left>
                          mdi-arrow-left
                        </v-icon>
                        Back
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn v-if="valid" class="ma-2 px-11" color="orange darken-2" dark elevation="4" x-large
                        @click="ReUploadRequirements">
                        Submit
                      </v-btn>
                      <v-btn v-else class="ma-2 px-11" color="blue-grey lighten-2" dark elevation="4" x-large>
                        sumbit
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="8">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4" lg="4">
                <v-card class="elevation-12 vcardbg" outlined>
                  <v-img src="/SSCR.png" alt="San Sebastian College-Recoletos" contain height="150" class="mt-5 mb-2">
                  </v-img>
                  <v-card-title>Forgot Password</v-card-title>
                  <v-card-text>
                    <v-divider class="linedivider my-5"></v-divider>
                    <div v-if="alert">
                      <v-alert type="success">
                        {{ message }}
                      </v-alert>
                    </div>
                    <div v-else>
                      <v-form lazy-validation v-model="valid" ref="RefForgotPassword">
                        <div v-if="error">
                          <v-alert type="error">
                            {{ message }}
                          </v-alert>
                        </div>
                        <v-text-field label="Enter Student No." prepend-inner-icon="mdi-account" v-model="studentno"
                          class="font-weight-bold" :rules="[rules.required]" outlined></v-text-field>
                        <v-text-field label="Enter Email Address" prepend-inner-icon="mdi-email" v-model="email_add"
                          class="font-weight-bold" :rules="[rules.required, rules.email]" outlined></v-text-field>
                      </v-form>
                    </div>
                  </v-card-text>
                  <v-divider class="linedivider"></v-divider>
                  <v-card-actions>
                    <v-btn class="ma-2" color="orange darken-2" dark elevation="4" x-large @click="clearinputs(1,'')">
                      <v-icon left>
                        mdi-arrow-left
                      </v-icon>
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div v-if="!alert">
                      <v-btn v-if="valid" class="ma-2 px-11" color="orange darken-2" dark elevation="4" x-large
                        @click="ForgotPassword">
                        <v-icon>mdi-envelope</v-icon>
                        Send
                      </v-btn>
                      <v-btn v-else class="ma-2 px-11" color="blue-grey lighten-2" dark elevation="4" x-large>
                        <v-icon>mdi-envelope</v-icon>
                        Send
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="orange darken-2" dark>
        <v-card-text v-if="register" class="text-center">
          <div>
            <h3>Registering.....</h3>
          </div>
          <v-progress-circular :width="8" :size="70" color="white" indeterminate class="mx-10"></v-progress-circular>
        </v-card-text>

        <v-card-text v-if="!register" class="text-center">
          <div class="py-5">
            <h3> {{ message }}</h3>
          </div>
          <v-progress-circular :width="8" :size="70" color="white" indeterminate class="mx-10"></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="PrivacyPolicy" width="70%">
      <v-card>
        <v-card-title class="text-h4">
          Privacy Policy
        </v-card-title>
        <v-card-text class="al-justify font-weight-black">
          {{ PrivacyPolicycontent }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="orange" @click="PrivacyPolicy = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ErrorDialog" max-width="550px">
      <v-card>
        <v-card-title class="text-h6 justify-center">{{ ErrorMessage }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="ErrorDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import studentService from '@/services/student'
import moment from 'moment'
import _ from 'lodash'
import VueCookies from 'vue-cookies'

export default {
  name: 'Login',
   data: vm => ({
    alert: false,
    ErrorDialog: false,
    ErrorMessage: '',
    OriginalDocuments: false,
    register: true,
    valid: true,
    dialog: false,
    step: 1,
    degree: '',
    gender: '',
    genderValue: '',
    bdate: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    age: '',
    address: '',
    telno: '',
    nationality: '',
    nationalities: [],
    religion: '',
    religions: [],
    password: '',
    confirmpassword: '',
    birthcertificate: null,
    selectedBC: '',
    selectedBT: '',
    selectedFT: '',
    selectedGM: '',
    lrn: '',
    baptismal: null,
    form138: null,
    goodmoral: null,
    studentno: '',
    email: '',
    firstname: '',
    lastname: '',
    middlename: '',
    yearlevel: '',
    classtype: '',
    lastschool: ' ',
    classtypevalue: [],
    submitStatus: '',
    degrees: [],
    studDetails: [],
    studentNoLog: '',
    docRequiredDefault: [
      {'code': 'BC','doc_name': 'BirthCertificate' },
      {'code': 'FORM', 'doc_name': 'Form 137 / 138'},
      {'code': 'BAP', 'doc_name': 'Baptismal'},
      {'code': 'GM', 'doc_name': 'Good Moral'}
      ],
    docRequired: [],
    tempdocRequired: [],
    docSubmit:[],
    progid: '',
    state: '',
    files: [],
    DataPrivacy: false,
    PrivacyPolicy: false,
    st: false,
    studFound: true,
    show1: false,
    uploadfiles:[],
    knowledge: 0,
    message:'',
    error: false,
    registerMessage: '',
    city: '',
    zipcode: '',
    civstatus: '',
    birthplace: '',
    father: '',
    mother: '',
    guardian: '',
    hometelno: '',
    email_add:'',
    menu1: false,
    isVerified: false,
    datePick: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    allowtypes: ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"],
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 8) || 'Minimum of 7 characters',
      max: v => (v && v.length <= 50) || 'Maximum of 50 characters',
      maxphone: v => (v && v.length <=13) || 'Maximum of 13 numbers',
      minphone: v => (v && v.length >=7) || 'Must not lesser than 7 numbers',
      minMember: v => (v && v.length >= 6) || 'Min 6 characters',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      maximagesize: v => !v || v.size < 2000000 || 'Upload size document should be less than 2 MB!',
      age: v => v > 2 || 'Please Enter Correct Birth Date',
      AcceptedFiletype: v => {
        if (v) {
           if (vm.allowtypes.includes(v.type)) {
             return true
           } else {
             return 'Only image and pdf document are accepted'
           }
          } else {
            return true
          }

        }

},
    genders: [
      {'value' : 'M', 'text' : 'Male'},
      {'value' : 'F', 'text' : 'Female'}
      ],
    classification: [
      {'value' : 'F', 'text' : 'Freshman'},
      {'value' : 'T', 'text' : 'Transferee'},
      {'value' : 'R', 'text' : 'Returnee'},
      {'value' : 'C', 'text' : 'Cross Enrolee'}
    ],
    civil_status: [
      {'value' : 'S', 'text' : 'Single'},
      {'value' : 'M', 'text' : 'Married'},
      {'value' : 'D', 'text' : 'Divorced'},
      {'value' : 'W', 'text' : 'Widowed'}
    ],
PrivacyPolicycontent: ''

  }),
   props: {
    source: String
  },
  mounted () {
    this.GetDegrees()
    this.GetNationalities()
    this.GetReligions()
    this.setPrivacyPolicy()
  },
  computed: {
    computedDateFormatted () {

        return this.formatDate(this.datePick)
      },
    passwordMatch () {
      if (this.password === this.confirmpassword) {
        return true
      } else {
        return 'Password did not match'
      }
    },
    GetAge () {
      if (this.age > 1) {
        return true
      } else {
        return 'Age must not be lesser than 2 years old'
      }
    },
    GetLastName () {
      let studLastName = typeof this.studDetails.lastname === 'string' ? this.studDetails.lastname.trim() : ''
      let studInputLasName = typeof this.lastname === 'string' ? this.lastname.trim().toUpperCase() : ''

      if (studLastName === studInputLasName) {
        return true
      } else {
        return 'Last Name did not match'
      }
    },
    GetFirstName () {
      let studFirstName
      if (typeof this.studDetails.firstname == 'string' ) {
        studFirstName = this.studDetails.firstname.trim()
      }

      let studInputFirstName = typeof this.firstname === 'string' ? this.firstname.trim().toUpperCase() : ''

      if (studFirstName === studInputFirstName) {
        return true
      } else {
        return 'First Name did not match'
      }
    },
    GetMiddleName () {
      if (_.isEmpty(this.studDetails.midname)) {
        return true
      }
      if (this.studDetails.midname === this.middlename.substring(0,1).toUpperCase() || this.studDetails.midname === this.middlename.toUpperCase()) {
        return true
      } else {
        return 'Middle name/initial did not match'
      }
    },
    GetGenderStud () {
      try {
      if (this.studDetails.gender === this.genderValue) {
          return true
        } else {
          return 'Gender did not match'
        }
      } catch (error) {
        console.log(error)
      }

    },
    GetBirthDateStud () {

      if (moment(this.studDetails.birth_date).format('MM/DD/YYYY') === moment(this.bdate).format('MM/DD/YYYY')) {
        return true
      } else {
        return 'Birth Date did not match'
      }
    },
    AcceptFileTypeBirthCertificate () {
     const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
      if (this.birthcertificate) {
        if (allowtypes.includes(this.birthcertificate.type)) {
          return true
        } else {
          return "Only image and pdf document are accepted"
        }
      } else {
        return true
      }
    },
    AcceptFileTypeCredential () {
     const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
      if (this.form138) {
        if (allowtypes.includes(this.form138.type)) {
          return true
        } else {
          return "Only image and pdf document are accepted"
        }
      } else {
        return true
      }
    },
    AcceptFileTypeBaptismal () {
     const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
      if (this.baptismal) {
        if (allowtypes.includes(this.baptismal.type)) {
          // this.uploadfiles.push(this.baptismal)
          return true
        } else {
          return "Only image and pdf document are accepted"
        }
      } else {
        return true
      }
    },
    AcceptFileTypeGoodMoral () {
     const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
      if (this.goodmoral) {
        if (allowtypes.includes(this.goodmoral.type)) {
          // this.uploadfiles.push(this.goodmoral)
          return true
        } else {
          return "Only image and pdf document are accepted"
        }
      } else {
        return true
      }
    },
    // AcceptedFiletype () {
    //   const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
    //   if (this.docSubmit) {
    //     console.log(this)
    //     console.log(this.docSubmit)

    //    return true
    //   } else {
    //     return true
    //   }
    //   return true
    // }
  },
  watch: {
      datePick (val) {
        this.bdate = this.formatDate(this.datePick)
      },
      bdate: function () {
        if (this.state === 'cs') {
          this.CompleDetails()
        }
      },
      lastname: function () {
        if (this.state === 'cs') {
          this.CompleDetails()
        }
      },
      fisrtname: function () {
        if (this.state === 'cs') {
          this.CompleDetails()
        }
      },
      gender: function () {
        if (this.state === 'cs') {
          this.CompleDetails()
        }
      },


  },
  methods: {
    OrigDocument () {
      if (this.OriginalDocuments) {
        this.tempdocRequired = this.docRequired
        this.docRequired = []
      } else {
        this.docRequired = this.tempdocRequired
      }
    },
    async setPrivacyPolicy() {
      try {
        const result = await studentService.GetPrivacyPolicy()
        if (result.status === 200) {
          this.PrivacyPolicycontent = result.data.result
        }
      } catch (error) {
        console.log(error)
      }
    },
    async SetDocumentsRequired () {
      try {
        const response = await studentService.CheckDocReference({
          programid: this.degree.program_id
        })
        if (response.status === 200) {
          if (response.data.count > 0) {
            this.docRequired = response.data.result
          } else {
            this.docRequired = this.docRequiredDefault
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    CompleDetails () {
      if (this.studDetails.lastname === this.lastname.toUpperCase() &&
          this.studDetails.firstname === this.firstname.toUpperCase() &&
          this.studDetails.gender === this.genderValue &&
          moment(this.studDetails.birth_date).format('MM/DD/YYYY') === moment(this.bdate).format('MM/DD/YYYY')) {
            const city = this.studDetails.city_addr ? this.studDetails.city_addr : ''
            const street =  this.studDetails.home_addr_street
            this.address = street + ', ' + city
            this.telno = this.studDetails.home_tel_no
            this.nationality = this.studDetails.nat_cd
            this.religion = this.studDetails.relgn_no

            this.degree = this.progid

          } else {
            this.address = ''
            this.telno = ''
            this.nationality = ''
            this.religion = ''
            this.degree = ''
          }

    },
    async ReUploadRequirements(){
      try {

        if (!this.$refs.ReUploadForm.validate()) {
        } else {
           this.dialog = false
        const formData = new FormData()
        this.docRequired.forEach( i => {
          if (this.docSubmit[i.code]) {
            formData.append('files', this.docSubmit[i.code], this.RenameUploads(this.docSubmit[i.code].name,i.code))
          }
        })
        // console.log(this.degree.program_id)
        const data = {
          email: this.email,
          password: this.password,
          prog_id: this.degree.program_id
        }
        formData.append('data',JSON.stringify(data))
        const response = await studentService.ReUploadeNewRequirements(formData)
        if (response.status === 200) {
          this.dialog = false
          this.registerMessage = "Done re-uploading requirements please check your email for further instruction!"
          this.step = 5
          setTimeout(() => {
            this.step = 1
          }, 10000)
        }
        }
      } catch (error) {
        console.log(error)
        this.dialog = false
        this.error = true
        this.message = error.response.data.message

      }lo
    },
    RenameUploads (file,filename) {
      const ext = file.split('.').pop()
      return filename + '.' + ext
    },
    GetGenderValue () {
      this.genderValue = this.gender.value
    },
    async reSendVerification() {
      try {
        const result = await studentService.ReSendVerification(this.studentNoLog)
        if (result.status === 200) {
          this.message = "Please Check email for verification"
          this.isVerified = false
        }
      } catch (error) {
        this.message = "Something wrong with your email please try again."
        console.log(error)
      }
    },
    async Login () {
      try {
        if (this.$refs.LoginForm.validate()){
          this.register = false
          const response = await studentService.Login({
            appno: this.studentno,
            password: this.password
          })
          this.studentNoLog = this.studentno
          if (response.status === 200){
            this.dialog = true
            this.message = response.data.message
               setTimeout(() => {
                //localStorage.setItem('token',response.data.token)
                //VueCookies.set('Token', response.data.token, '2h')
                this.$router.push({name: 'Home'})
              }, 3000)
          } else if ( response.status === 202) {
            console.log(response.data)
            this.error = true
            this.isVerified = true
            this.message = response.data.message
          }
        }
      } catch (e) {
         this.error = true
         this.message = e.response.data.message
        console.log(e)
      }

    },
    async RegisterNewStudent () {

      if (!this.$refs.NewStudentForm.validate()) {

      } else {
        if (!this.DataPrivacy) {
          this.ErrorDialog = true
          this.ErrorMessage = 'Please check i agree on our privacy policy to register!'
          return
        }
       this.dialog = true

        const formData = new FormData()
        this.docRequired.forEach( i => {

          formData.append('files', this.docSubmit[i.code], this.RenameUploads(this.docSubmit[i.code].name,i.code))
        })
        // if (this.birthcertificate) {
        //   this.selectedBC = 'BC' + this.birthcertificate.name
        //   formData.append('files', this.birthcertificate, this.RenameUploads(this.birthcertificate.name,'BirthCertificate'))
        // }
        // if (this.form138) {
        //   this.selectedFT = 'FT' + this.form138.name
        //   formData.append('files', this.form138, this.RenameUploads(this.form138.name,'Credential'))
        // }
        // if (this.baptismal) {
        //   this.selectedBT = 'BT' + this.baptismal.name
        //    formData.append('files', this.baptismal, this.RenameUploads(this.baptismal.name,'Baptismal'))
        // }
        // if (this.goodmoral) {
        //   this.selectedGM = 'GM' + this.goodmoral.name
        //   formData.append('files', this.goodmoral, this.RenameUploads(this.goodmoral.name,'Goodmoral'))
        // }
        const data = {
          lastname: this.lastname.toUpperCase(),
          firstname: this.firstname.toUpperCase(),
          middlename: this.middlename.toUpperCase(),
          gender: this.gender.value.toUpperCase(),
          prog_id: this.degree,
          apptype: this.classtypevalue,
          age: this.age,
          gen_ave: 0,
          act_taken: 0,
          test_result: 0,
          email: this.email,
          prevschool: this.lastschool.toUpperCase(),
          address: this.address.toUpperCase(),
          mobileno: this.telno,
          birthdate: this.bdate,
          birthplace: this.birthplace.toUpperCase(),
          civstatus: this.civstatus,
          hometelno: this.hometelno,
          city: this.city.toUpperCase(),
          zipcode: this.zipcode,
          father: this.father.toUpperCase(),
          mother: this.mother.toUpperCase(),
          guardian: this.guardian.toUpperCase(),
          natcd: this.nationality,
          relgn: this.religion,
          baptismal: this.selectedBT,
          birthcertificate: this.selectedBC,
          form138: this.selectedFT,
          goodmoral: this.selectedGM,
          password: this.password,
          lrn: this.lrn
        }
        formData.append('data',JSON.stringify(data))
          const response = await studentService.RegisterNewStudents(formData, {
             onUploadProgress: e => this.knowledge = Math.round(e.loaded * 100 / e.total)

          })

        if (response.status === 200) {
          this.dialog = false
          this.registerMessage = response.data.message
          this.step = 5
          setTimeout(() => {
            this.$router.go()
          }, 5000)
        } else {
          this.dialog = false
          this.step = 6
           setTimeout(() => {
           this.step = 3
         }, 5000)
        }
      }

    },
    async RegisterContinuingStudent () {
      if (this.$refs.ContinuingStudentForm.validate()) {
        this.dialog = true
        const response = await studentService.RegisterContinuingStudent({
          password: this.password,
          studentno: this.studentno,
          email:this.email,
          lastname: this.lastname,
          firstname: this.firstname
        })
        if (response.status === 200) {
          this.dialog = false
          this.registerMessage = response.data.result
          this.step = 5
           setTimeout(() => {
             this.$router.go()
          }, 3000)
        } else {
          this.dialog = false
          this.step = 6
        }
      } else {
        console.log(error)
      }
    },
    async GetDegrees () {
      try {
        const response = await studentService.GetDeegres()
        if (response.status === 200) {
          this.degrees = response.data.data
        }
      } catch (err) {
        console.log('Error Message: ', err)
      }
    },
    async GetNationalities () {
      try {
        const response = await studentService.GetNationalities()
        if (response.status === 200) {
          this.nationalities = response.data.result
        }
      } catch (error) {
          console.log('Error Message: ', error)
      }
    },
    async GetReligions() {
      try {
        const response = await studentService.GetReligions()
        if (response.status === 200) {
          this.religions = response.data.result
        }
      } catch (error) {
        console.log('Error Message: ', error)
      }
    },
    clearinputs (val,state) {
      this.state = state
      this.email = ''
      this.degree = ''
      this.bdate = ''
      this.yearlevel = ''
      this.lastname = ''
      this.firstname = ''
      this.middlename = ''
      this.password = ''
      this.confirmpassword = ''
      this.studentno = ''
      this.gender = ''
      this.birthcertificate = null
      this.baptismal = null
      this.form138 = null
      this.goodmoral = null
      this.classtype = ''
      this.nationality = ''
      this.telno = ''
      this.religion = ''
      this.step = val
      this.age = ''
      this.lastschool = ''
      this.valid = true
      this.alert = false
      this.message = ''
      this.error = false
      this.email_add = ''
    },
    ComputeAge () {
      let today = new Date()
      let birthdate = new Date(this.bdate)

      let age = today.getFullYear() - birthdate.getFullYear()
      let month = today.getMonth() - birthdate.getMonth()
      if (month < 0 || (month=== 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      this.age = age
      this.menu1 = false
    },
    getclasstypevalue () {
      if (this.classtype.value === 'T' || this.classtype.value === 'C'){
        this.lastschool = null

        this.classtypevalue = this.classtype.value
      } else {
        this.lastschool = ' '
        this.classtypevalue = this.classtype.value
      }
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async ForgotPassword() {
      try {
        if (this.$refs.RefForgotPassword.validate()){
          const result = await studentService.ForgotStudentPassword({
            studentno: this.studentno,
            email: this.email_add
          })
          if (result.status === 200) {
            this.alert = true
            this.message = result.data.message
          }
        }
      } catch (error) {
        this.error = true
        this.message = error.response.data.error
      }
    }
   },
  asyncComputed: {
     async checkEmail () {
      try {
        const response = await studentService.CheckEmailExist({
          email: this.email
        })
        if (response.status === 200) {
          if (response.data.result > 0) {
            return 'E-mail already exists'
          } else {
            return true
          }
        } else {
          return true        }
      } catch (err) {
        console.log('Error Message: ', err)
      }
    },
    async GetStudentNo () {
      try {
        if (_.isEmpty(this.studno)) {
          return '';
        }
        const response = await studentService.GetStudentNo({
          studentno: this.studentno
        })
        if (response.status === 200) {
          if(response.data.result > 0) {
            this.studFound = false
            return true
          } else {
            this.studFound = true
             return 'Student No. did not exists'
          }
        } else {
          this.studFound = true
          return 'Student No. did not exists'
        }
      } catch (error) {
        console.log('Error Message: ', error)
      }
    },
     async CheckStudentNoRegistered () {
      try {
        if (_.isEmpty(this.studentno)) {
          return true;
        }
        const response = await studentService.GetStudentNo({
          studentno: this.studentno
        })
        if (response.status === 200) {

          if(response.data.result > 0) {
            if(_.isEmpty(response.data.data.pswrd)){
              this.studFound = false
              return true
            } else {
              this.studFound = true
              return 'Student No. already registered. Please login'
            }
          } else {
            this.studFound = false
             return 'Student No. did not exists'
          }
        } else {
          this.studFound = true
          return 'Student No. already registered'
        }
      } catch (error) {
        console.log('Error Message: ', error)
      }
    },
    async GetStudentDetails () {
      try {
        const response = await studentService.GetStudentDetails({
          studno: this.studentno,
          email: this.email
        })
        if (response.status === 200) {
          if(response.data.data > 0) {
            this.studDetails = response.data.result
            this.progid = response.data.programid
            return true
          } else {
            this.studDetails = []
            this.progid = ''
             return "E-mail address did not found!"
          }
        } else {
          this.studDetails = []
          this.progid = ''
          return "E-mail address did not found!"
        }
      } catch (error) {
        console.log('Error Message: ', error)
      }
    }
  }
}
</script>
<style>




/* .v-btn__content {
  color: #e46e52;
} */

.full-height {
  align-items: center;
  height: 100%;
}
.al-justify {
  text-align: justify;
  text-justify: inter-word;
}
.v-btn:not(.v-btn--round).v-size--default{
  padding: 0 !important;
  color: #0000EE;
}
.theme--dark input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("/calendar.png") no-repeat;
  background-size: 24px 24px;
}
.inputTelNo input[type='number'] {
    -moz-appearance:textfield;
}
.inputTelNo input::-webkit-outer-spin-button,
.inputTelNo input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.linedivider{
  border-color: black !important;
}
.vcardbg {
  background-color: rgba(255,204,0,1) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-background-clip: text;
}

.error--text {
    color: #ff0000 !important;
    caret-color: #ff0000 !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(128 128 128/ 50%) !important;
}
.theme--dark.v-btn.v-btn--disabled {
    color: rgba(255, 255, 255, 1) !important;
}

.uppcase input{
  text-transform: uppercase
}
.forgetpass {
  padding: 0 0 !important;
}
</style>
