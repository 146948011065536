<template>
    <div>
        <v-container class="mt-4 pa-5 mb-5">
            <v-row dense v-if="headerView && refresh">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table :headers="headers" :items="account_hdr" class="elevation-1" hide-default-footer>
                        <template v-for="header in headers.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )" v-slot:[`item.${header.value}`]="{ header, value }">
                            {{ header.formatter(value) }}
                        </template>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>STUDENT ACCOUNTS</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon left @click="ViewDetails(item.termcd)" color="orange darken-2">
                                            mdi-file-document
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>View Details</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon left @click="Payment(item.termcd, student.stud_no)"
                                            color="orange darken-2">
                                            mdi-credit-card-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Payment</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row dense v-else>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table :headers="headerDetail" :items="account_dtl" class="elevation-1" hide-default-footer>
                        <template v-for="header in headerDetail.filter((header) =>
                            header.hasOwnProperty('formatter')
                        )" v-slot:[`item.${header.value}`]="{ header, value }">
                            {{ header.formatter(value) }}
                        </template>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>STATEMENT OF ACCOUNT</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold mr-2" color="orange darken-2" dark small
                                    @click="GetAccounts">
                                    <v-icon small left class="mr-2">
                                        mdi-arrow-left
                                    </v-icon>
                                    Back
                                </v-btn>
                                <v-btn class="font-weight-bold" color="orange darken-2" dark small @click="print">
                                    <v-icon small left class="mr-2">
                                        mdi-printer
                                    </v-icon>
                                    Print
                                </v-btn>
                            </v-toolbar>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-spacer></v-spacer>
                    <div class="mt-2 m-2">
                        <h3 align="right" color="black" dark>TOTAL CURRENT BALANCE: <strong class="text-xl-h4 text-lg-h5 text-md-h5">₱{{ totalBalance }}</strong>
                        </h3>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="PaymentDialog" transition="dialog-bottom-transition" max-width="600">
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar color="orange darken-2" dark>
                        Online School Fee Payment Through DragonPay
                    </v-toolbar>
                    <v-card-text class="mt-5">
                        <v-alert v-show="PayErrorMessageShow" type="error" dark text>{{ PayErrorMessage }}</v-alert>
                        <v-text-field label="Please Enter Amount" outlined placeholder="0.00" v-model="AmountPayment"
                            @keypress="filter()" @change="formatNumber()" class="font-weight-bold"
                            hint="Note: A 20 pesos charge will be added as DragonPay service fee" persistent-hint>
                        </v-text-field>

                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="blue darken-1" dark @click="ClosePaymentDialog()" class="pad-buttons white--text">
                            <v-icon left>
                                mdi-close
                            </v-icon>
                            Close
                        </v-btn>
                        <v-btn color="orange darken-2" class="pad-buttons white--text" dark @click="ToPay()">
                            <v-icon left>
                                mdi-credit-card-outline
                            </v-icon>
                            Payment
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <div hidden>
            <div id="printMe">
                <div class="title">
                    <span>San Sebastian College-Recoletos</span>
                </div>
                <div class="sub-title">
                    <span>Claro M. Recto Ave., Manila, Philippines</span>
                </div>
                <div class="study-load-title">
                    <span>STUDENT ACCOUNTS</span>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Student Number:</span><span class="ml-1"><b> {{ student.stud_no }} </b></span>
                    </div>
                    <div class="column cols4">
                        <span> Admission Status:</span><span class="ml-1">Freshman</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Name: </span><span class="ml-1">{{ full_name }}</span>
                    </div>
                    <div class="column cols4">
                        <span>Age:</span><span class="ml-1 mr-1"> {{ studAge }}</span>
                        <span>Gender:</span><span class="ml-1 mr-1">{{ studGender }}</span>
                        <span>Status:</span><span class="ml-1 mr-1">{{ studStatus }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column cols6">
                        <span>Course & Year:</span><span class="ml-1">{{ studCourseYear }}</span>
                    </div>
                    <div class="column cols4">
                        <span> Semester/School Year:</span><span class="ml-1">{{ term }}</span>
                    </div>
                </div>
                <v-row dense class="mb-8">
                    <v-col>
                        <v-data-table :headers="headerDetail" :items="account_dtl" class="elevation-1"
                            hide-default-footer>
                            <template v-for="header in headerDetail.filter((header) =>
                                                    header.hasOwnProperty('formatter')
                                                )" v-slot:[`item.${header.value}`]="{ header, value }">
                                {{ header.formatter(value) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
import studentService from '@/services/student'
import numeral from 'numeral'
import moment from 'moment'
import printjs from 'print-js'
import _ from 'lodash'

export default {
    name: 'StudentAccounts',
    props: ['student','refresh'],
    data() {
        return {
        account_hdr: [],
        account_dtl: [],
        headerView: true,
        PaymentDialog: false,
        TermPayment: '',
        StudentNo: '',
        AmountPayment: '',
        PayErrorMessage: '',
        PayErrorMessageShow:false,
        full_name: '',
        studAge: '',
        studGender: '',
        studStatus: '',
        studCourseYear: '',
        term: '', 
        totalBalance: 0,

        }
    },
    computed: {
        headers () { 
            return [
                { text: 'ROW', align: 'start', 'sortable': false, value: 'row' },
                { text: 'Term', value: 'term' },
                { text: 'Account', value: 'account' },
                { text: 'Debit', value: 'debit', formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
                { text: 'Credit', value: 'credit', formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
                { text: 'Balance', value: 'balance', formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
                { text: 'Program Id', value: 'program_id', align: 'center'},
                { text: 'Action', value: 'actions', sortable: false, align: 'center' },
                { text: 'termcd', value: 'termcd', align: ' d-none'}
            ]
        },
        headerDetail () {
            return [
                { text: 'Tran Date', value: 'tran_date', sortable: false, formatter: (x) => (x ? moment(x).format('MMMM DD, YYYY hh:mm A') : x)},
                { text: 'Reference', value: 'refno', sortable: false },
                { text: 'Particulars', value: 'particular', sortable: false },
                { text: 'Debit', value: 'debit', sortable: false, formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
                { text: 'Credit', value: 'credit', sortable: false, formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
                { text: 'Balance', value: 'balance', sortable: false, formatter: (x) => (x ? this.formatExample(x) : ''), align: 'right', class: 'mr-1' },
            
            ]
        }
    },
    mounted() {
        this.GetAccounts()
        this.GetTotalBalance()
        
    },
    methods: {
        formatExample(value) {

            if (value >= 0) {
                return numeral(value).format('0,0.00')
            } else {
                if (typeof value !== 'string') {
                    value = value * -1

                    return '(' + numeral(value).format('0,0.00') + ')'
                } else {
                    return numeral(value).format('0,0.00')
                }
            }


        },
        async GetTotalBalance () {
            try {
                const result = await studentService.GetTotalBalance(this.student.stud_no);
                if (result.data.success) {
                    this.totalBalance = this.formatExample(result.data.balance);
                } else {
                    this.totalBalance = result.data.balance;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async GetAccounts() {
            try {
                this.headerView = true
                const result = await studentService.GetAccounts(this.student.stud_no)
                if (result.status === 200) {
                    let data = []
                    this.account_hdr = []
                    result.data.result.forEach((value, index) => {
                        data = []
                        data = {
                            row: index + 1,
                            term: this.SetTerm(value.term_cd),
                            account: value.acct_name,
                            debit: value.debit,
                            credit: value.credit,
                            balance: value.debit - value.credit,
                            program_id: value.program_id,
                            termcd: value.term_cd

                        }

                        this.account_hdr.push(data)


                    })

                   
                }
            } catch (error) {
                console.log(error)
            }
        },
        SetTerm(termcd) {
            const tt = termcd.toString().substring(6,4)
            const year = termcd.toString().substring(0,4)
            const endyear = parseInt(year) + 1
            let term
            switch (tt) {
                case '00':
                    term = 'School year ' + year + ' - ' + endyear
                    break;
                case '21':
                    term = '1st Semester ' + year + ' - ' + endyear
                    break;
                case '22':
                    term = '2nd Semester ' + year + ' - ' + endyear
                    break;
                case '23':
                    term = 'Summer ' + endyear
                    break;
                case '31':
                    term = '1st Trimester ' + year
                    break;
                case '32':
                    term = '2nd Trimester ' + year
                    break;
                case '33':
                    term = '3rd Trimester ' + year
                    break;
                case '34':
                    term = '4th Trimester ' + year
                    break;
                case '41':
                    term = '1st Quarter ' + year
                    break;
                case '42':
                    term = '2nd Quarter ' + year
                    break;
                case '43':
                    term = '3rd Quarter ' + year
                    break;
                case '44':
                    term = '4th Quarter ' + year
                    break;
                default:
                    break;
            }
            return term
        },
        async ViewDetails(val) {
            try {
                this.headerView = false
                const result = await studentService.GetStudentAccountsDetails({
                    student_no: this.student.stud_no,
                    term: val
                })
                if (result.status === 200) {
                    this.account_dtl = []
                    let balance = 0
                    result.data.result.forEach((value, index) => {
                        let data = []

                        if (value.term_cd === val) {
                            balance += value.debit - value.credit
                            
                            data = {
                                tran_date: value.tran_dt,
                                refno: value.refno,
                                particular: value.particulars,
                                debit: value.debit,
                                credit: value.credit,
                                balance: balance
                            }

                            this.account_dtl.push(data)
                        }
                        
                    })
                    await this.SetPrintOutData(this.student.stud_no,val)
                }
                
            } catch (error) {
                console.log(error)
            }
        },
        formatExample(value) {
                
                if (value  >= 0) {
                    return numeral(value).format('0,0.00')
                } else {
                    if (typeof value !== 'string') {
                        value = value * -1

                        return '(' + numeral(value).format('0,0.00') + ')'
                    } else {
                        return numeral(value).format('0,0.00')
                    }
                }
    
            
        },
        formatNumber(){
            this.AmountPayment = this.formatExample(this.AmountPayment)
        },
        filter: function () {
     
            let evt =  window.event;
            let expect = evt.target.value.toString() + evt.key.toString();

            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true
            }
        },
        print() {

        },
        ClosePaymentDialog(){
            this.TermPayment = ''
            this.StudentNo = ''
            this.AmountPayment = ''
            this.PaymentDialog = false
            this.PayErrorMessageShow = false
        },
        Payment(term,student_no) {
            this.PaymentDialog = true
            this.TermPayment = term
            this.StudentNo = student_no

        },
        async ToPay() {
            if (!this.AmountPayment) {
                this.PayErrorMessage = 'Please enter a valid amount'
                this.PayErrorMessageShow = true
                this.AmountPayment = ''
                return
            }
             if (parseFloat(this.AmountPayment.replace(/,/g, '')) < 100) {
                this.PayErrorMessage = 'Minimum payment is 100.00'
                this.PayErrorMessageShow = true
                return
            }

            this.PayErrorMessageShow = false
            try {
                let amountpay = parseFloat(this.AmountPayment.replace(/,/g, '')) + 20
                
                const result = await studentService.GetDragonPayUrl({
                    amount: amountpay.toFixed(2),
                    student_no: this.StudentNo,
                    term_cd: this.TermPayment,
                    PaymentType: 1,
                })
                if (result.status === 200) {
                    this.PaymentDialog = false
                    window.location = result.data.result.url
                    //console.log(result.data.result)

                    // const Dpay = this.DragonPay + '&invoiceno=' +
                    // //window.location = 'https://facebook.com'
                }
            } catch (error) {
                console.log(error)
            }
        },
        async SetPrintOutData(studentno, term) {
            this.term = this.SetTerm(term)
            const result = await studentService.GetProgramEnrolled({
                student_no: studentno,
                term: term
            })
            const data = result.data.result[0]
            console.log(data)
            let today = new Date()
            let dday = moment(today).format('DD')
            let mmonth = moment(today).format('MMMM')
            let yyear = moment(today).format('YYYY')
            switch (dday.toString().substring(1, 2)) {
                case 1:
                    this.dday = dday.toString() + 'st'
                    break;
                case 2:
                    this.dday = dday.toString() + 'nd'
                    break;
                case 3:
                    this.dday = dday.toString() + 'rd'
                    break;
                default:
                    this.dday = dday.toString() + 'th'
                    break;
            }
            let birthdate = new Date(this.student.birth_date)
            let yrlvl
            this.mmonth = mmonth
            this.yyear = yyear
            let age = today.getFullYear() - birthdate.getFullYear()
            let month = today.getMonth() - birthdate.getMonth()
            if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                age--;
            }
            if (data.program_id === 'KINDER') {
                switch (data.yr_level) {
                    case 1:
                        yrlvl = 'Kinder 1'
                        break;
                    case 2:
                        yrlvl = 'Kinder 2'
                        break;
                    default:
                        break;
                }
            } else if (data.program_id === 'ELEM') {
                switch (data.yr_level.toString()) {
                    case 'K1':
                        yrlvl = 'Kinder 1'
                        break;
                    case 'K2':
                        yrlvl = 'Kinder 2'
                        break;
                    case '1':
                        yrlvl = 'Grade 1'
                        break;
                    case '2':
                        yrlvl = 'Grade 2'
                        break;
                    case '3':
                        yrlvl = 'Grade 3'
                        break;
                    case '4':
                        yrlvl = 'Grade 4'
                        break;
                    case '5':
                        yrlvl = 'Grade 5'
                        break;
                    case '6':
                        yrlvl = 'Grade 6'
                        break;
                    default:
                        break;
                }
            } else if (data.program_id === 'HS' || data.program_id === 'K12') {
                switch (data.yr_level) {
                    case 7:
                        yrlvl = 'Grade 7'
                        break;
                    case 8:
                        yrlvl = 'Grade 8'
                        break;
                    case 9:
                        yrlvl = 'Grade 9'
                        break;
                    case 10:
                        yrlvl = 'Grade 10'
                        break;
                    case 11:
                        yrlvl = 'Grade 11'
                        break;
                    case 12:
                        yrlvl = 'Grade 12'
                        break;
                    default:
                        break;
                }
            } else {
                switch (parseInt(data.yr_level)) {
                    case 1:
                        yrlvl = 'First Year'
                        break;
                    case 2:
                        yrlvl = 'Second Year'
                        break;
                    case 3:
                        yrlvl = 'Third Year'
                        break;
                    case 4:
                        yrlvl = 'Fourth Year'
                        break;
                    case 5:
                        yrlvl = 'Fifth Year'
                        break;
                    default:
                        break;
                }
            }

            let lastname = _.isNull(this.student.lastname) || _.isEmpty(this.student.lastname) ? '' : this.student.lastname
            let firstname = _.isNull(this.student.firstname) || _.isEmpty(this.student.firstname) ? '' : this.student.firstname
            let midname = _.isNull(this.student.midname) || _.isEmpty(this.student.midame) ? '' : this.student.midname.substring(0, 1) + '.'
            this.full_name = lastname + ', ' + firstname + ' ' + midname
            this.studAge = age
            this.studGender = this.student.gender === 'M' ? 'Male' : 'Female'
            this.studCourseYear = data.program_id + ' - ' + yrlvl
            this.studDateEnrolled = moment(this.dateEnrolled).format('MMMM DD, YYYY')
            let address = _.isNull(this.student.home_addr_street) || _.isEmpty(this.student.home_addr_street) ? '' : this.student.home_addr_street + ', '
            let city = _.isNull(this.student.city_addr) || _.isEmpty(this.student.city_addr) ? '' : this.student.city_addr
            this.studAddress = address + city
            this.StudyLoad = this.DownPayment > this.TotCredits ? 'OFFICIAL ' : 'OFFICIAL '
        },
        print() {
            let currdate = new Date()
            this.today = moment(currdate).format('MMMM DD, YYYY hh:mm A')
            printjs({
                printable: 'printMe', type: 'html', showModal: true, font_size: "",
                style: ['@page { size: letter; margin: 2mm;} body {margin: 5px 10px 5px 10px;} div {margin:0; padding: 0;} h4 {margin:0}', `
                    * {
                        box-sizing: border-box;
                    }
                    body {
                        font-size: 10px;
                    }
                    div {
                        padding: 0 !important;
                        margin: 0 !important;
                        height: 25% !important;
                    }
                    table {
                        height: 10% !important;
                    }
                    .fl {
                        float: left
                    }
                    .no-pads2{
                        margin-top: 100px !important;
                        padding-top: 10000px !important;
                    }

                    .fr {
                        float: right;
                        font-size: 18px;

                    }
                    br {
                        margin: 0;
                    }
                    .no-pads {
                       margin-bottom: 10px !important;
                       margin-top: 10px !important;
                    }
                    .title {
                        font-family: "Old English Text MT";
                        text-align: center;
                        font-size: 20px !important;
                        padding-top: 0;
                        margin-top: 0;
                    }

                    .sub-title {
                        text-align: center;
                        padding: 0;
                        margin: 0;
                        font-size: 16px;
                    }

                    .study-load-title {
                        text-align: center;
                        margin-top: 2px;
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }

                    .study-load-sub-title {
                        text-align: center;
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .promisory-title {
                        text-align: center;
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 800;
                        text-decoration: underline;
                    }

                    .column {
                        float: left;
                        padding: 0;
                        margin: 0;
                    }

                    .left,
                    .right {
                        width: 20%;
                    }

                    .middle {
                        width: 60%;
                    }

                    .row {
                        padding: 0 !important;
                        margin: 0 !important;
                    }

                    .bold {
                        font-weight: 800;
                        font-size: 12px;
                    }

                    .cols1 {
                        width: 10%;
                    }

                    .cols2 {
                        width: 20%;
                    }

                    .cols2-1 {
                        width: 25%;
                    }

                    .cols3 {
                        width: 30%
                    }

                    .cols4 {
                        width: 40%;
                    }

                    .cols4-1 {
                        width: 35%;
                    }

                    .cols5 {
                        width: 50%;
                    }

                    .cols6 {
                        width: 60%;
                    }

                    .cols7 {
                        width: 70%;
                    }

                    .cols10 {
                        width: 100%;
                    }

                    .ml-1 {
                        margin-left: 10px;
                    }

                    .mt-1 {
                        margin-top: 10px;
                    }

                    .ml-2 {
                        margin-left: 20px;
                    }
                    .mr-1 {
                        margin-right: 5px;
                    }

                    .mr-2 {
                        margin-right: 20px;
                    }

                    .mt-2 {
                        margin-top: 20px;
                    }

                    .mb-2 {
                        margin-bottom: 20px;
                    }

                    .ml-3 {
                        margin-left: 30px;
                    }

                    .mr-3 {
                        margin-right: 30px;
                    }

                    .mt-3 {
                        margin-top: 30px;
                    }

                    .mb-3 {
                        margin-bottom: 30px;
                    }

                    .ml-4 {
                        margin-left: 40px;
                    }

                    .mr-4 {
                        margin-right: 40px;
                    }

                    .mt-4 {
                        margin-top: 40px;
                    }

                    .mb-4 {
                        margin-bottom: 40px;
                    }

                    .ml-5 {
                        margin-left: 50px;
                    }

                    .mr-5 {
                        margin-right: 50px;
                    }

                    .mt-5 {
                        margin-top: 50px;
                    }

                    .mb-5 {
                        margin-bottom: 50px;
                    }

                    .text-right {
                        text-align: right;
                        font-size: 10px;
                    }

                    .text-center {
                        margin: 5px 0;
                        text-align: center;
                        font-size: 10px;
                    }

                    .notes {
                        font-size: 10px;
                    }

                    table {
                        font-size: 10px;
                        width: 100%;
                        padding: 0 10px 0 0  !important;
                        margin: 0 10px 0 0 !important;

                    }
                    th {
                        padding: 0 !important;
                        margin:0 !important;
                        height:10% !important;
                    }

                     tr {
                        height: 1% !important;
                    }
                    table,
                    th,
                    tr {
                        border: 1px solid black !important;
                        border-collapse: collapse;
                    }
                  
                    table tr td:nth-child(2) {
                        text-align: left;
                    }

                    table tr td:nth-child(7) {
                        margin-right: 5px !important;
                    }

                   
                    tr, td {
                        text-align: center;
                        padding: 0 10px 0 0  !important;
                        margin: 0 10px 0 0 !important;
                        
                    }

                    .indent-text {
                        text-indent: 20px;
                    }

                    .text-10 {
                        font-size: 10px;
                    }

                    .no-border
                    {
                        border: none !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        height: 2px !important;
                    }
                    .no-border tr {
                        padding: 0 !important;
                        margin: 0 !important;
                    }
                    .no-border td {
                        padding: 0 !important;
                        margin: 0 !important;
                    }

                    .no-border td:nth-child(1) {
                        text-align: left !important;
                    }

                    .textbold {
                        font-weight: bold;
                    }
                    @media print {
                        p {page-break-after: always;}
                    }
                        `,
                ],
            })
        },                      
    }
}
</script>
<style>
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

table tr td {
    font-size: 12px;
}
.pad-buttons {
    padding: 20px !important;
}
.fl {
    float: left
}

.fr {
    float: right;
    font-size: 10px;

}

.title {
    font-family: "Old English Text MT";
    text-align: center;
    font-size: 50px !important;
    margin-bottom: 5px;
    font-weight: 600;
}

.sub-title {
    text-align: center;
    padding: 0;
    margin: 0;

    font-size: 16px;
}

.study-load-title {
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
}

.study-load-sub-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.promisory-title {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
}

.column {
    float: left;
    padding: 3px;
}

.left,
.right {
    width: 20%;
}

.middle {
    width: 60%;
}

.row::after {
    content: "";
    display: table;
    clear: both;
}

.bold {
    font-weight: 800;
    font-size: 12px;
}

.cols1 {
    width: 10%;
}

.cols2 {
    width: 20%;
}

.cols2-1 {
    width: 25%;
}

.cols3 {
    width: 30%
}

.cols4 {
    width: 40%;
}

.cols4-1 {
    width: 35%;
}

.cols5 {
    width: 50%;
}

.cols6 {
    width: 60%;
}

.cols7 {
    width: 70%;
}

.cols10 {
    width: 100%;
}

.ml-1 {
    margin-left: 10px;
}

.mt-1 {
    margin-top: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.mr-2 {
    margin-right: 20px;
}

.mt-2 {
    margin-top: 20px;
}

.mb-2 {
    margin-bottom: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.mr-3 {
    margin-right: 30px;
}

.mt-3 {
    margin-top: 30px;
}

.mb-3 {
    margin-bottom: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.mr-4 {
    margin-right: 40px;
}

.mt-4 {
    margin-top: 40px;
}

.mb-4 {
    margin-bottom: 40px;
}

.ml-5 {
    margin-left: 50px;
}

.mr-5 {
    margin-right: 50px;
}

.mt-5 {
    margin-top: 50px;
}

.mb-5 {
    margin-bottom: 50px;
}

.text-right {
    text-align: right;
    font-size: 12px;
}

.text-center {
    margin: 5px 0;
    text-align: center;
    font-size: 12px;
}

.notes {
    font-size: 10px;
}

table {
    width: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

.indent-text {
    text-indent: 30px;
}

.text-10 {
    font-size: 11px;
}

.no-border,
td {
    border: none;
}

.textbold {
    font-weight: bold;
}

.ddnone {
    display: none;
}

.v-card__actions>.v-btn.v-btn {
    padding: 0 8px !important;
}

.notify-bg {
    background-color: rgba(59, 161, 255, 0.2);
    ;
}
</style>
