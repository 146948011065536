<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
#app {
  background: url('/sccrmnl_bldg.jpg') no-repeat center center fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
  opacity: 0.7;
}
</style>