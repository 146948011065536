<template>
    <div>

        <v-container>
            <h1 class="text-center mt-3 mb-3">Document Upload</h1>
            <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-card
                        elevation="15"
                        outlined
                    >
                        <v-card-title>
                            <v-checkbox
                                color="amber darken-2"
                                v-model="chk"
                                value="documents"
                                label="Upload Required Documents"
                                @change="checkinputs"
                            >
                                
                            </v-checkbox>
                            </v-card-title>
                        <v-card-text>
                            <v-card :disabled="abledocs">
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-form lazy-validation v-model="uploadDocuments" ref="udForm">
                                                <v-file-input
                                                    v-model="birthcertificate"
                                                    class="font-weight-bold"
                                                    counter
                                                    label="Upload Birth Certificate"
                                                    placeholder="Please select a file"
                                                    prepend-icon="mdi-paperclip"
                                                    outlined
                                                    :show-size="1000"
                                                    :rules="[rules.maximagesize, AcceptFileTypeBirthCertificate]"
                                                    accept="image/png, image/jpeg, image/bmp, application/pdf"
                                                    clearable
                                                >
                                                </v-file-input>
                                                <v-file-input
                                                    v-model="form137"
                                                    class="font-weight-bold"
                                                    counter 
                                                    label="Upload Form 137"
                                                    placeholder="Please select a file"
                                                    prepend-icon="mdi-paperclip"
                                                    outlined
                                                    :show-size="1000"
                                                    :rules="[rules.maximagesize, AcceptFileTypeForm137]"
                                                    accept="image/png, image/jpeg, image/bmp, application/pdf"
                                                    clearable
                                                >
                                                </v-file-input>
                                                <v-file-input
                                                    v-model="goodmoral"
                                                    class="font-weight-bold"
                                                    counter
                                                    label="Upload Good Moral"
                                                    placeholder="Please select a file"
                                                    prepend-icon="mdi-paperclip"
                                                    outlined
                                                    :show-size="1000"
                                                    :rules="[rules.maximagesize, AcceptFileTypeGoodMoral]"
                                                    accept="image/png, image/jpeg, image/bmp, application/pdf"
                                                    clearable
                                                >
                                                </v-file-input>
                                                <v-file-input
                                                    v-model="baptismal"
                                                    class="font-weight-bold"
                                                    counter
                                                    label="Upload Baptismal"
                                                    placeholder="Please select a file"
                                                    prepend-icon="mdi-paperclip"
                                                    outlined
                                                    :show-size="1000"
                                                    :rules="[rules.maximagesize, AcceptFileTypeBaptismal]"
                                                    accept="image/png, image/jpeg, image/bmp, application/pdf"
                                                    clearable
                                                >
                                                </v-file-input>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                     <v-btn 
                                        class="font-weight-bold"
                                        color="orange darken-2"
                                        dark
                                        x-large
                                        block
                                        @click="UploadDocument"
                                    >Upload Document</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-card
                        elevation="15"
                        outlined
                    >
                        <v-card-title>
                              <v-checkbox
                                color="amber darken-2"
                                v-model="chk"
                                value="payments"
                                label="Upload Payments Reciept/Bank Deposits"
                                @change="checkinputs"
                            >
                            </v-checkbox>
                        </v-card-title>
                        <v-card-text>
                            <v-card :disabled="ablepay">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select
                                                hint="Term"
                                                persistent-hint
                                                class="font-weight-bold"
                                                outlined
                                                label="Select Term"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                hint="Amount"
                                                persistent-hint
                                                class="font-weight-bold"
                                                placeholder="Enter Amount"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-file-input
                                                v-model="payment"
                                                class="font-weight-bold"
                                                counter
                                                label="Upload Payment Reciept / Bank Deposit Slip"
                                                placeholder="Please select a file"
                                                prepend-icon="mdi-paperclip"
                                                outlined
                                                :show-size="1000"
                                                :rules="[rules.maximagesize, AcceptFileTypePayment]"
                                                accept="image/png, image/jpeg, image/bmp, application/pdf"
                                                clearable
                                            >
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                     <v-btn 
                                        class="font-weight-bold"
                                        color="orange darken-2"
                                        dark
                                        x-large
                                        block
                                        @click="UploadPayment"
                                    >Upload Payment</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'UploadDocuments',
    props: ['student'],
    data: () => ({
        birthcertificate: null,
        form137: null,
        goodmoral: null,
        baptismal: null,
        payment: null,
        uploadfiles: [],
        uploadDocuments: true,
        chk: false,
        abledocs: true,
        ablepay: true,
        rules: {
            required: value => !!value || 'Required.',
            maximagesize: v => !v || v.size < 2000000 || 'Upload size document should be less than 2 MB!',
        },
    }),
    computed: {
        AcceptFileTypePayment () {
            const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
            if (this.payment) {
                if (allowtypes.includes(this.payment.type)) {
                        return true
                } else {
                    return "Only image and pdf document are accepted"
                }
            } else {
                return true
            }
        },
        AcceptFileTypeBirthCertificate () {
            const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
            if (this.birthcertificate) {
                if (allowtypes.includes(this.birthcertificate.type)) {
                        return true
                } else {
                    return "Only image and pdf document are accepted"
                }
            } else {
                return true
            }
        },
        AcceptFileTypeForm137 () {
            const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
            if (this.form137) {
                if (allowtypes.includes(this.form137.type)) {
                    return true
                } else {
                    return "Only image and pdf document are accepted"
                }
            } else {
                return true
            }
        },
        AcceptFileTypeBaptismal () {
            // console.log(this.uploadDocuments)
            const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
            if (this.baptismal) {
                if (allowtypes.includes(this.baptismal.type)) {
                    return true
                } else {
                    return "Only image and pdf document are accepted"
                }
            } else {
                return true
            }
        },
        
        AcceptFileTypeGoodMoral () {
            const allowtypes = ["image/jpeg", "image/png" , "image/gif", "image/bmp", "application/pdf"]
            if (this.goodmoral) {
                if (allowtypes.includes(this.goodmoral.type)) {
                    return true
                } else {
                    return "Only image and pdf document are accepted"
                }
            } else {
                return true
            }
        }
    },
    methods: {
        submit() {
            // console.log(this.uploadfiles)
        },
        checkinputs() {
            if (this.chk === 'documents') {
                this.abledocs = false
                this.ablepay = true
            } else if (this.chk === 'payments') {
                this.abledocs = true
                this.ablepay = false
            } else {
                this.abledocs = true
                this.ablepay = true
            }
        },
        UploadPayment () {
            // console.log('uploadPayments')
        },
        UploadDocument () {
            // console.log('UploadDocuments')
        }

    }   

}
</script>
<style scoped>
.v-messages__message {
    color: rgba(0, 0, 0, 1) !important;
}
.theme--light.v-messages {
    color: rgba(0, 0, 0, 1) !important;
}
</style>